@import "../../../../../style/utils/";

.rental-ms-form {
  padding-bottom: 60px;
  & > .header {
    margin: 0 1.5rem 1.5rem 1.5rem;
  }

  .rental-form-title {
    display: flex;
    align-items: center;

    & > h2 {
      font-weight: 400;
      margin: 0;
    }
    button {
      display: none;
      margin: 0 0.5rem;
      padding-bottom: 0;
      .p-button-icon {
        font-size: 2rem;
        color: black;
      }
      .p-button-label {
        display: none;
      }
    }
  }

  .rental-form-id {
    font-size: 14px;
    color: #8d8d8d;
  }

  .title {
    font-size: 20px;
    font-weight: 400;
  }
  .steps {
    margin: 3rem 0;

    .p-steps ul {
      justify-content: center;
    }

    .p-steps .p-steps-item {
      position: relative;
      display: flex;
      justify-content: center;
      flex: none;
      min-width: 160px;
    }

    .p-steps .p-steps-item .p-menuitem-link {
      background: transparent;
    }

    .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
      color: #ffffff;
      /* border: 1px solid #e9ecef; */
      background: #8d8d8d;
    }
  }

  .footer {
    background-color: var(--surface-d);
    height: 60px;
    margin: -1.8rem -1.6rem;
    padding: 0rem 1.6rem;
    width: calc(100vw - 6rem);
    position: fixed;
    bottom: 25px;
    right: 21px;
    z-index: 100;

    @include md-screen {
      left: 107.2px;
    }

    .secondary {
      color: var(--secondary-color-text);
      border-color: var(--secondary-color-text);
    }

    .p-button {
      min-width: 70px;
      display: inline;
    }

    .next-button {
      margin-left: 1rem;
    }
  }

  @include sm-screen {
    .header {
      margin: 0;
    }

    .header-sticky {
      position: sticky;
      top: 0;
    }

    .rental-form-title {
      margin: 0;
      button {
        display: block;
        margin: 0 0.5rem;
        & > span {
          font-size: 2rem;
          color: black;
        }
      }
    }

    .rental-form-id {
      margin-left: 48px;
    }

    .steps {
      margin: 1.5rem 0rem 0rem 0rem;
    }

    .footer {
      width: calc(100vw);
      position: fixed;
      right: 22px;

      .back-button {
        display: none;
      }

      .p-button {
        height: 44px;
        text-align: start;
      }
    }
  }
}

.rental-create {
  @include sm-screen {
    &.rental-ms-form {
      .footer {
        padding: 0rem 1rem;
        .right-button-group {
          flex: 1 1 auto;
          display: flex;
        }

        .p-button {
          flex: 1;
        }
      }
    }
  }
}
