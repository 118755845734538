@import "../../../../style/utils/index.scss";
.newsfeed {
  padding: 0;
  margin: 0;
}
.newsfeed-title {
  background-color: var(--surface-e);
  color: var(--primary-color-text);
  font-weight: bold;
  padding: 0;
  margin: 0;
  border-radius: 3px;
  // padding: 1rem !important;
  // position: relative;
  margin-top: 1.5rem !important;
  display: flex;
  justify-content: space-between;
  p {
    margin: 1rem;
  }
}

.newsfeed-readall-btn {
  padding: 0.25rem 0.375rem;
  margin: 0.5rem 1rem;
  font-weight: normal;
  background: var(--surface-b);
  border-color: var(--surface-b);
  color: var(--secondary-color-text);
}

.newsfeed-group-panel-skeleton {
  transition: all 0.4s;
  padding: 0rem;
  margin: 0rem;
  margin-bottom: 1rem;
  background-color: var(--surface-a);
  display: flex;
  justify-content: space-between;
  div {
    margin: 0rem;
  }
  .p-skeleton {
    margin: 1rem;
  }
}

.newsfeed-group-panel {
  transition: all 0.4s;
  margin-bottom: 1.5rem;
  background-color: var(--surface-a);
  // flex-wrap: nowrap;
  // flex-direction: row;
  padding: 1rem;
  .newsfeed-divider {
    margin: 0.5rem !important;
  }
  div {
    display: flex;
    // align-content: center;

    .newsfeed-logo {
      // margin: 1px;
      margin: 0rem;
      padding: 0rem;
      .newsfeed-icon {
        margin-top: 3px;
        height: 2rem;
      }
    }

    .newsfeed-divider {
      padding: 0rem;
      margin: 0rem;
      opacity: 0.2;
    }

    .newsfeed-content-true {
      padding: 0rem;
      margin: 0;
      p {
        /* Instead use this non-standard one: */
        word-break: break-word;
        white-space: pre-wrap;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;

        margin: 0;
        min-width: 100%;
        min-height: 100%;
      }
    }

    .newsfeed-content-false {
      padding: 0rem;
      margin: 0;
      p {
        /* Instead use this non-standard one: */
        word-break: break-word;
        white-space: pre-wrap;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;

        margin: 0;
        padding: 0rem;
        min-width: 100%;
        min-height: 100%;
        font-weight: bold;
      }
    }

    .newsfeed-content-btn {
      justify-content: flex-end;
      p {
        padding: 0rem;
        margin: 0rem;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      a {
        overflow: hidden;
        text-overflow: ellipsis;
        flex-flow: column nowrap;
      }
    }
  }
}

.newsfeed-mobile {
  background-color: var(--surface-e) !important;
  color: var(--primary-color-text) !important;
  padding: 0rem;
  margin: 1rem 0rem 0rem 0rem;
  border-radius: 3px !important;
}

.newsfeed-mobile + div {
  > .p-panel-content {
    padding: 0rem;
    border: 0rem;
  }
}

.newsfeed-panel-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0;
  min-width: 115px;
}

.newsfeed-mobile .newsfeed-readall-btn {
  margin: 0 10px;
}

.newsfeed-title-mobile {
  font-weight: bold !important;
  padding: 0;
  margin: 0;
}

.newsfeed-group-panel-mobile {
  margin-bottom: 0rem !important;
}
