.filter-dropdown {
  height: 45px;
  width: 216px;

  .p-dropdown-label {
    display: flex;
    align-items: center;
    font-size: 14px;

    .pi {
      margin: 0 0.7rem 0 0.5rem;
    }
  }
}
