@import "../../../../style/utils/mixins";
.ep-login {
  flex-flow: column;
  height: 100%;
}
.ep-login-no-margin {
  margin: 0;
  display: flex;
  height: 100%;
}

.ep-login-bg {
  margin: 0;
  padding: 0;
  position: relative;
  background: var(--primary-color-text);
  flex-grow: 1;
  max-width: calc(100vw - 30rem);
  @include sm-screen {
    display: none;
  }

  .ep-login-img-full {
    position: relative;
    // align-items: center;
    // text-align: center;
    // justify-content: space-around;
    // height: 100%;
    // width: 100%;
    // margin: 0;
    background: var(--surface-b) 0% 0% no-repeat padding-box;
    // .lazy-load-image-background {
    //   height: 100%;
    // }
    // img {
    //   max-width: 100%;
    // }
    display: flex;
    text-align: center;
    height: 100%;
    align-items: center;
    justify-content: space-around;
    // * {
    //   flex-grow: 1;
    // }
    .lazy-load-image-loaded {
      display: flex !important;
      align-items: center;
      img {
        max-width: 100%;
      }
    }
  }
  .ep-login-img-full-custom {
    position: relative;
    height: 100%;
    width: 100%;
    margin: 0;
    background: var(--surface-b) 0% 0% no-repeat padding-box;
    overflow: hidden;
    .lazy-load-image-background {
      height: 100%;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .ep-login-info {
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    bottom: 5em;
    left: 1em;
    color: black;
  }
}

// @media screen and (min-width: 575px) {
//   .ep-login-panel {
//     background-color: white;
//     min-height: 100vh;
//     min-width: 100%;
//   }
// }

// @media screen and (min-width: 400px) {
//   .ep-login-panel {
//     background-color: white;
//     min-height: 100vh;
//     min-width: 100%;
//   }
// }

.ep-login-panel {
  background: var(--primary-color-text);
  flex-grow: 1;
  min-height: 100%;
  // width: max-content;
  // min-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  // .animated {
  //   width: 100%;
  //   height: 100%;
  // }
}

.ep-login-top-toolbar {
  padding: 0;
  margin: 0;
}

.ep-login-panel-form {
  height: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
  @include sm-screen {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .ep-login-header {
    text-align: center;
    .logo {
      max-width: 144px;
      max-height: 102px;
    }
  }
  .ep-login-body {
    // margin-bottom: 8rem;
    .checkbox-row {
      display: flex;
      align-items: center;
      .forgot {
        text-decoration: none;
      }
    }
    .actions {
      hr {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
      }
      button {
        width: 100%;
        padding: 0;
        max-width: 100%;
        text-align: center;
        span {
          width: 100%;
          padding: 0;
        }
      }
    }
    .p-inputgroup {
      position: unset;
    }
  }
}

.ep-first-login-email {
  color: var(--primary-color);
}

.ep-first-login-info-list {
  list-style: none;
  margin: 0rem;
  padding: 0rem;
  border: 0rem;
  .ep-first-login-list {
    padding: 0.75rem 1rem;
  }
}

.ep-first-login-menu {
  width: 100%;
  .ep-first-login-info-title {
    padding: 0.75rem 1rem;
    margin: 0rem;
    font-weight: 600;
    color: var(--primary-color);
  }
  .ep-first-login-info-icon {
    color: var(--primary-color);
    margin-right: 0.5rem;
  }
  .ep-first-login-rule-fulfill {
    color: var(--primary-color);
  }
}

.login-skeleton {
  margin-left: 22rem;
  margin-top: -1.9rem;
  .p-skeleton {
    background-color: transparent !important;
  }
}

.ep-login-policies {
  color: black;
}

.login-language {
  .p-button-secondary {
    // background: white;
    // color: black !important;
    // width: 40px !important;
    // height: 40px !important;
    color: var(--button-language-primary) !important;
    margin-top: 0 !important;
    width: 108px !important;
    height: 48px !important;
    padding: 0;
    .language-icon-size {
      width: 23px;
      height: 23px;
    }
  }
}

.login-go-back {
  .p-button.p-button-text {
    color: black !important;
  }
}

.hris-login-changeTenantClient-dialog
  > .p-dialog-footer
  > .carbon-btn-group-full {
  justify-content: center;
  > div {
    flex-grow: 0 !important;
  }
}
