@import "../../../../../style/utils";

.leave-balance {
  @include sm-screen {
    padding: 0.5rem 0.5rem 3rem 0.5rem;
  }

  .leave-section-wrapper {
    background: #fff;
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;

    @include sm-screen {
      &:first-child {
        margin-top: 0;
      }

      margin-top: 1.5rem;
    }
  }

  .leave-summary {
    .summary-block {
      margin: 2rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 0 1 25%;
      cursor: pointer;

      &.flex-width {
        flex: 1;
      }

      &:not(:nth-child(4n)) {
        border-right: 2px solid #707070;
      }

      &:last-child {
        border-right: none;
      }

      @include sm-screen {
        margin: 1rem 0;
        flex-basis: 50%;

        &:nth-child(2n) {
          border-right: none;
        }
      }

      .summary-title {
        padding: 0 20px;
        text-align: center;
      }

      .summary-days {
        font-weight: bold;
        padding: 0.25rem 0;
      }
    }
  }

  .leave-filter {
    .filter-block-employee {
      margin: 1rem;
    }
    .filter-block {
      margin: 2rem;
      @include sm-screen {
        margin: 1rem;
      }

      .base-control {
        display: flex;
        flex-direction: column;
      }

      .filter-label {
        font-size: 13px;
        color: #515151;
        margin-bottom: 0.25rem;
      }

      .filter-leave-type {
        width: 300px;
      }

      .filter-year {
        width: 220px;
      }
    }

    @include sm-screen {
      .filter-block__leave-type {
        width: 100%;

        .filter-leave-type {
          width: 100%;
        }
      }

      .filter-block__year {
        width: 100%;

        .filter-year {
          width: 100%;
        }
      }
    }

    .filter-block-employee.filter-block__self {
      padding-top: 3rem;
    }
  }

  .leave-detail {
    margin-bottom: 2rem;

    .detail-wrapper {
      margin: 2rem;
      width: 100%;

      @include sm-screen {
        margin: 1rem;
      }
    }

    .detail-info-container {
      position: relative;
    }

    .bt-addbtn {
      position: absolute;
      top: 6px;
      right: 0;
      background: transparent;
      border-color: transparent;

      &:hover {
        background: #e9ecef;
        border-color: #ced4da;
        color: #495057;
      }

      .p-button-icon {
        color: var(--text-color-secondary);
      }
    }

    .detail-info {
      width: 100%;
      background: var(--surface-b);
      display: flex;
      flex-wrap: wrap;
      padding-right: 40px;

      .detail-info-field {
        margin: 1rem;

        .detail-info-label {
          font-weight: bold;
          margin-right: 5px;
        }
      }

      @include sm-screen {
        .detail-info-field__employee {
          // display: none;
        }

        .detail-info-field {
          margin: 0.25rem 0.5rem;

          &.detail-info-field__leave-type {
            width: 100%;
          }

          &.detail-info-field__from,
          &.detail-info-field__to {
            width: 50%;
          }
        }
      }
    }

    .leave-balance-table {
      .p-datatable .p-datatable-thead > tr > th {
        color: var(--surface-f);
        background: var(--surface-g);
      }

      .p-datatable .p-datatable-tbody > tr {
        background: var(--surface-b);

        &.p-row-expanded {
          background: #fff;
        }
      }

      .table-balance-item {
        width: 80%;
      }

      @include sm-screen {
        .table-balance-item {
          width: 65%;
        }
        // .p-datatable .p-datatable-thead > tr > th:first-child,
        // .p-datatable .p-datatable-tbody > tr > td:first-child {
        //   display: none;
        // }

        .p-datatable .p-datatable-thead > tr > th,
        .p-datatable .p-datatable-tbody > tr > td {
          padding: 0.5rem;
        }
      }

      .leave-balance-transaction-table {
        .leave-balance-transaction-table-header {
          background: var(--surface-b);
          color: var(--surface-f);
          font-weight: bold;
          padding: 1rem;
          @include sm-screen {
            padding: 0.5rem;
          }
        }

        .p-sortable-column {
          position: relative;

          &:hover {
            .p-sortable-column-icon {
              opacity: 1;
            }
          }
        }

        .p-column-title {
          display: block;
        }

        .p-sortable-column-icon {
          opacity: 0;
          position: absolute;
          right: 1rem;
          top: calc(50% - 0.5rem);
        }

        .cell-custom-small {
          width: 8rem;
        }

        .cell-custom-medium {
          width: 10rem;
        }

        .cell-custom-large {
          width: 11rem;
        }

        .cell-custom-extra-large {
          width: 12rem;
        }

        .leave-balance-transaction-table-desktop {
          overflow-x: scroll;
        }

        .leave-balance-transaction-table-mobile {
          display: none;
        }

        @include sm-screen {
          .leave-balance-transaction-table-desktop {
            display: none;
          }
          .leave-balance-transaction-table-mobile {
            display: block;

            .p-datatable-thead > tr > th {
              display: none;
            }

            .p-datatable-tbody > tr {
              border-bottom: 1px #e0e0e0 solid !important;
            }

            .p-datatable-tbody > tr > td {
              background: #fff !important;
              width: 100% !important;
              max-width: 100%;
              float: left;
              clear: left;
              border: 0 none;

              .bt-cell {
                display: flex;
                justify-content: space-between;
                font-size: 12px;

                .bt-cell-value {
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }
  }
}
