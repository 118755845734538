@import "../../../../style/utils";

.rental-menu {
  display: none;

  @include sm-screen {
    display: block;
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .rental-tab-menu {
    overflow-x: scroll;

    .p-tabmenu-nav {
      flex-wrap: nowrap;
      .p-tabmenuitem {
        flex: 1 1;

        &.p-highlight .p-menuitem-link {
          background: var(--surface-b);
        }
      }

      .p-menuitem-link {
        padding: 0.6rem;
        border-bottom-width: 3px;
        background: var(--surface-b);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .p-menuitem-icon {
          margin-right: 0;
        }
      }

      .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
        background: var(--surface-b);
      }

      .p-menuitem-icon {
        width: 20px;
        height: 20px;
        margin-bottom: 3px;
        fill: var(--surface-b);
        color: red;
      }

      .rental-menu-icon {
        &-rental_applications {
          content: url("./icon/icon_e-rental_rental-applications.svg");
        }
        &-add_new_application {
          content: url("./icon/icon_e-rental_add-new-application.svg");
        }
        &-rental_approver_corner {
          content: url("./icon/icon_e-rental_rental-approver-corner.svg");
        }
        &-search_report {
          content: url("./icon/icon_e-rental_search-report.svg");
        }
        &-view_all_rental_applications {
          content: url("./icon/icon_e-rental_search-report.svg");
        }
      }

      .p-tabmenuitem.p-highlight .p-menuitem-icon {
        color: var(--sidebar-active-menu);
        filter: brightness(0) saturate(100%) invert(14%) sepia(61%)
          saturate(6173%) hue-rotate(350deg) brightness(81%) contrast(90%);
      }

      .p-menuitem-text {
        width: max-content;
        min-width: 100%;
        font-size: 11px;
        text-align: center;
      }
    }
  }
}
