@import "../../../../../style/utils/";

.rental-submit-claim-upload {
  @include sm-screen {
    .footer {
      display: block;
      padding: 0.5rem;
      .next-button {
        margin: 0;
        width: 100%;
      }
    }
  }
}
