.claim-status {
  font-size: 13px;
  word-break: break-word;
  display: flex;
  align-items: baseline;

  &__indicator {
    height: 8px;
    width: 8px;
    min-height: 8px;
    min-width: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 6px;
    &--open {
      background-color: #bfbfbf;
    }

    &--pending-for-verification {
      background-color: #ffff00;
    }

    &--verified {
      background-color: #00b050;
    }

    &--rejected {
      background-color: #ff0000;
    }

    &--returned {
      background-color: #0000ff;
    }

    // &--approved {
    //   background-color: #24a047;
    // }

    // &--in-progress {
    //   background-color: #ffb100;
    // }

    // &--not-submitted {
    //   background-color: #ee3135;
    // }
  }
}
