@import "../../../../../style/utils";
.rental-approve-claim {
  & > .title {
    margin: 0 1.5rem 1.5rem 1.5rem;
    font-weight: 400;
    font-size: 20px;
    display: flex;
    align-items: center;

    @include sm-screen {
      margin: 0;
    }

    button {
      display: none;
      padding-bottom: 0;
      margin: 0 0.5rem;

      @include sm-screen {
        display: block;
      }

      .p-button-icon {
        font-size: 2rem;
        color: black;
      }

      .p-button-label {
        display: none;
      }
    }
  }

  @include sm-screen {
    padding-bottom: 60px;
  }

  @media print {
    .form-date-readyonly-value {
      display: flex !important;
      min-height: 27px;
      align-items: center;
      font-size: 1.075rem;
    }

    .date-control {
      display: none;
    }
  }

  .footer {
    background-color: var(--surface-d);
    height: 60px;
    margin: -1.8rem -1.6rem;
    padding: 0rem 1.6rem;
    width: calc(100vw - 6rem);
    position: fixed;
    bottom: 25px;
    right: 21px;
    z-index: 100;

    @include md-screen {
      left: 107.2px;
    }

    @include sm-screen {
      width: 100vw;
      padding: 0 1rem;
      right: 19px;
    }

    .secondary {
      color: var(--secondary-color-text);
      border-color: var(--secondary-color-text);
    }

    .p-button {
      min-width: 70px;
      display: inline;
      &.p-disabled {
        opacity: 0.3;
      }
    }

    .next-button {
      margin-left: 1rem;
    }

    .button-group-1 {
      button {
        margin-right: 1rem;
      }

      @include sm-screen {
        display: none;
      }
    }

    .button-group-2 {
      display: flex;

      button:not(:first-child) {
        margin-left: 10px;
      }

      @include sm-screen {
        flex: 1;

        button {
          flex: 1;
          height: 44px;
          text-align: start;
          padding: 0.1rem 0.5rem;
        }
      }
    }
  }
}

.finalize-claim-modal {
  max-width: 500px;

  & > div {
    margin-bottom: 1rem;
  }

  input {
    width: 100%;
  }
}
