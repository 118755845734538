@import "/src/style/utils";

.rental-approval-list {
  padding: 0 1rem;

  .title {
    font-size: 20px;
    font-weight: 400;
  }

  .filter-container {
    margin: 2rem 0;

    .p-dropdown {
      width: 100%;
    }
  }

  .rental-approval-list-table {
    & > .p-card > .p-card-body {
      padding-top: 0.25rem;
    }

    & > .p-card > .p-card-body > .p-card-content {
      padding: 0;
    }

    .bt-table {
      min-height: calc(100vh - 28rem) !important;
      .bt-header {
        // display: none;
        .bt-header-toolbar {
          display: none;
        }

        .bt-toolbar {
          margin: 0;
          .bt-action .bt-filterbtn.p-highlight {
            background: var(--primary-color) !important;
            border-color: var(--primary-color) !important;
          }
        }

        .p-filter-column {
          padding: 7px;
        }
      }

      .p-datatable .p-datatable-thead > tr > th.p-filter-column .p-field {
        // background: var(--surface-b);
        margin: 0;
        padding: 0px 2px;
      }

      .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr {
        cursor: pointer;
        background: var(--surface-b);
        color: #495057;
      }

      .p-datatable.p-datatable-hoverable-rows
        .p-datatable-tbody
        > tr:not(.p-highlight):hover {
        background: var(--surface-d);
        color: #495057;
      }
    }
  }

  @include sm-screen {
    tbody.p-datatable-tbody tr td {
      height: 46px;
      padding: 7px 10px;
    }

    tbody.p-datatable-tbody tr:not(:last-child) td {
      border-bottom: 1px solid !important;
      border-color: #e0e0e0 !important;
    }

    .datatable-responsive
      .p-datatable.p-datatable-responsive.disable-mobile
      table:not(.mobile-expander-header-style)
      .p-datatable-tbody > tr > td {
        width: auto !important;
      }
  }
}
