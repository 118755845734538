@import "../../../../../style/utils";

.leave-holiday {
  @include sm-screen {
    padding: 0.5rem;
  }

  .holiday-filter {
    background: #fff;
    display: flex;
    flex-wrap: wrap;

    .holiday-filter-block {
      padding: 2rem;

      @include sm-screen {
        padding: 1rem;
      }

      .base-control {
        display: flex;
        flex-direction: column;

        .filter-label {
          font-size: 13px;
          color: #515151;
          margin-bottom: 0.25rem;
        }

        .p-dropdown {
          width: 160px;

          @include sm-screen {
            width: 120px;
          }
        }
      }
    }
  }

  .leave-holiday-list-container {
    background: #fff;
    padding: 2rem;
    margin-top: 2rem;

    @include sm-screen {
      padding: 0.5rem;
      margin-bottom: 60px;
    }
  }
}
