@import "../../../../../style/utils/";
.rental-report {
  padding: 0 1rem;

  @include sm-screen {
    padding-bottom: 60px;
  }

  .title {
    font-size: 20px;
    font-weight: 400;
  }

  .filter-container {
    margin: 2rem 0;

    .p-dropdown {
      width: 100%;
    }
  }

  .filter-fields-container {
    .multi-step-form-group {
      margin: 0 0 2rem 0;
    }
  }

  .report-list-table {
    .bt-table {
      min-height: calc(100vh - 20rem) !important;

      .bt-cell-value {
        word-break: break-word;
      }

      .p-paginator {
        background: var(--surface-b) !important;
      }

      .p-paginator .p-dropdown {
        background: var(--surface-b) !important;
      }
    }

    .p-datatable .p-datatable-tbody > tr {
      background: var(--surface-b);
      cursor: pointer;
    }

    ::-webkit-scrollbar {
      background: var(--surface-b) !important;
    }

    @include sm-screen {
      .datatable-responsive .p-datatable.p-datatable-responsive {
        table:not(.mobile-expander-header-style) {
          .p-datatable-tbody > tr > td {
            width: calc(100vw - 5.5rem) !important;
          }
        }
      }
    }
  }

  .p-card-content {
    padding: 0;
  }

  .bt-title {
    font-weight: normal !important;
    padding-bottom: 10px;
  }

  .bt-toolbar {
    display: none !important;
  }

  .footer {
    background-color: var(--surface-d);
    height: 60px;
    margin: -1.8rem -1.6rem;
    padding: 0rem 1.6rem;
    width: calc(100vw - 6rem);
    position: fixed;
    bottom: 25px;
    right: 21px;
    z-index: 100;

    @include md-screen {
      left: 107.2px;
    }

    @include sm-screen {
      width: 100vw;
      padding: 0 1rem;
      right: 19px;
    }

    .secondary {
      color: var(--secondary-color-text);
      border-color: var(--secondary-color-text);
    }

    button {
      min-width: 70px;
      display: inline;
    }

    .button-group-2 {
      button:not(:first-child) {
        margin-left: 1rem;
      }
    }

    @include sm-screen {
      .button-group-1,
      .button-group-2 {
        display: flex;
        flex: 1;
      }

      .button-group-1 {
        button {
          margin-right: 10px;
        }
      }

      .button-group-2 {
        justify-content: flex-end;

        button:not(:first-child) {
          margin-left: 10px;
        }

        &.has-export-button {
          flex: 2;
        }
      }

      button {
        height: 44px;
        text-align: left;
        padding: 0.1rem 0.5rem;
        flex: 1;
      }
    }
  }
}
