@import "../../../../style/utils";

.assign-claim-control {
  .col-file-name {
    width: 25%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .col-month {
    width: 45%;
  }
  .action-column {
    width: 64px;
  }

  .p-multiselect {
    width: 85%;
    max-width: 300px;
  }

  .p-multiselect-label {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .p-multiselect-token {
    padding: 5px;
    background: var(--select-option-primary);
    border-radius: 5px;
    min-width: 87px;
    display: flex;
    justify-content: flex-end;
    .p-multiselect-token-icon {
      margin: 3px;
      margin-right: 0;
    }
  }

  .remove-button {
    border-radius: 0.5rem !important;
    height: 2rem;
    width: 2rem;
    & > span.pi {
      font-size: 1.3rem !important;
    }
  }
  .p-datatable-thead {
    th {
      background-color: var(--surface-d) !important;
    }
  }
  .p-datatable-tbody {
    & tr:first-child td {
      padding-top: 1.5rem;
    }
    & tr:last-child td {
      padding-bottom: 1.5rem;
    }
    & tr td {
      border: none;
      padding: 0.5rem 1rem;
    }
  }

  .p-rowgroup-header {
    & td {
      height: 1rem;
    }
  }

  @include sm-screen {
    .col-file-name {
      width: 35%;
    }

    .p-multiselect {
      width: 100%;
    }

    .p-datatable-tbody {
      & tr:first-child:not(.p-datatable-emptymessage) td {
        padding-top: 0;
        height: 0.5rem;
        .p-rowgroup-header-name {
          display: none;
        }
      }
      & tr:last-child td {
        padding-bottom: 1.5rem;
      }
      & tr td {
        border: none;
        padding: 0.5rem 1rem;
      }
    }
  }
}
