@import "../../../../../style/utils";

.leave-delegtaion {
  @include sm-screen {
    padding: 0.5rem;
  }

  .header {
    flex-wrap: wrap;
    @include sm-screen {
      justify-content: center;

      .title {
        width: 100%;
      }

      .title-desc {
        display: none;
      }

      .p-button {
        margin-bottom: 1.5rem;
      }
    }
  }

  .main {
    min-height: 100px;
    background: white;
  }

  .main > .p-card > .p-card-body > .p-card-content {
    padding: 0;
  }

  .delegation-tab {
    margin-bottom: 1.5rem;

    @include sm-screen {
      .p-dropdown {
        width: 100%;
      }
    }
  }

  .bt-table.bt-table-custom {
    min-height: 100px !important;
  }

  .bt-table .bt-header {
    display: none;
  }

  .bt-table tbody td {
    background: var(--surface-b);
    border-color: var(--surface-b);
  }

  th.bt-action-frozen-dummy {
    display: none;
  }
  td.bt-action-frozen-dummy {
    display: none;
  }

  .bt-table .p-datatable-scrollable-wrapper {
    max-height: 50vh;
    overflow-y: auto;
  }

  .p-datatable-unfrozen-view {
    left: 5rem !important;
    width: calc(100% - 5rem) !important;

    &:before {
      width: 0;
    }
  }
}
