@import "../../style/utils/index.scss";
.MainModal {
  .p-dialog {
    min-width: 40rem;
    max-width: calc(100vw - 2rem);
    @include sm-screen {
      min-width: calc(100vw - 2rem);
      max-width: calc(100vw - 2rem);
    }

    .p-dialog-footer {
      padding: 0;
    }
    .p-dialog-header .p-dialog-title {
      font-size: 2rem;
    }

    .modal-content {
      font-size: 1.5rem;
      font-weight: 500;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
    .modal-content-header {
      font-size: 2rem;
    }
  }
}

.main-modal {
  min-width: 250px;
}

.confirm-message-modal {
  width: 90%;
  max-width: 500px;
  min-width: 250px;

  .p-dialog-header {
    background-color: var(--surface-b);
    color: var(--secondary-color-text);
    padding: 0.75rem 1rem;

    .p-dialog-header-close-icon {
      color: var(--secondary-color-text);
    }
  }

  .p-dialog-content {
    background-color: var(--surface-b);
    padding: 0rem 1rem 1rem 1rem;
    min-height: 100px;
  }

  .p-dialog-footer {
    padding: 0;

    .p-button-secondary {
      background-color: #383838;
    }

    .p-button {
      border: none;
      text-align: left;
      padding: 12px 14px 30px 14px;
      height: 60px;
    }
  }

  .p-invalid-custom {
    display: none;
  }

  form {
    margin-top: 1rem;

    .base-control .p-component {
      background: white;
      border: 1px solid var(--surface-d);
    }

    .base-control .p-component.date-control:not(.p-invalid) {
      border: none;
    }

    .input-control-inner.p-field > label {
      margin-bottom: 0.5rem;
    }

    // Error
    .p-component.p-invalid {
      border: 0.2rem solid red;

      & .p-inputtext {
        border: none;
      }
    }

    // hide error text
    small.p-invalid-custom {
      display: none !important;
    }
  }
}

.form-modal {
  max-width: 700px;
}

.general-message-modal {
  width: 90%;
  max-width: 500px;
  min-width: 250px;

  .p-dialog-header {
    background-color: var(--surface-b);
    color: var(--secondary-color-text);
    padding: 0.75rem 1rem;

    .p-dialog-header-close-icon {
      color: var(--secondary-color-text);
    }

    .p-dialog-title {
      .pi {
        color: var(--message-error-primary);
        font-size: 20px;
        margin-right: 0.5rem;
      }
    }
  }

  .p-dialog-content {
    background-color: var(--surface-b);
    padding: 0rem 1rem 2rem 1rem;
    min-height: 100px;
  }

  .p-dialog-footer {
    padding: 0;

    .p-button {
      border: none;
      text-align: left;
      padding: 12px 14px 30px 14px;
      height: 60px;
    }

    .p-button-secondary {
      background-color: #383838;
    }

    .p-button.p-button-secondary {
      display: none;
    }

    .carbon-btn-group-full .p-button {
      width: 100% !important;
    }
  }
}
