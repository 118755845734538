@import "../../../style/utils";
.data-table-control-inner {
  .control-hint-bottom {
    display: inline-block;
    margin-top: 0.25rem;
  }

  .control-hint-right {
    display: flex;
    align-items: center;
    margin: 0 0 0 1rem;
  }
  .p-datatable.p-component.p-invalid {
    border: none !important;
  }
  .p-invalid {
    > td {
      div {
        border-color: #f44336;
      }
    }
  }

  // .data-table-row:hover {
  //   color: var(--surface-f);
  //   background: var(--surface-g);
  // }

  // .p-invalid:hover {
  //   color: var(--surface-f);
  //   background: var(--surface-g);
  // }

  .p-datatable .p-datatable-thead > tr > th {
    color: var(--surface-f);
    background: var(--surface-g);
  }
  .datatable-control-rowExpansionTemplate {
    display: flex;
    overflow-x: auto;
    @include sm-screen {
      flex-direction: column;
    }
    .datatable-control-rowExpansion-column {
      .p-column-title {
        display: block;
      }
    }
  }

  .bt-cell-value {
    @include sm-screen {
      direction: rtl;
    }
  }
}
