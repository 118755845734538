@import "./../../../../style/utils/";

.p-menubar-button {
  display: none !important;
}

.main-header {
  height: 82px;
  background: white;
  .p-menubar {
    background: white;
    // align-self: start;
    .p-menubar-start {
      margin-right: 1rem;
    }
  }
  /* make it fixed when small screen */
  height: 6rem;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  left: 0;
  width: 100%;
  top: 8rem;
  position: fixed;
  .main-header-right-content {
    flex-wrap: nowrap;
  }
}

.tenant-logo-container {
  flex-wrap: nowrap !important;
  .tenant-logo-size {
    height: 42px;
  }
}

.tenant-left-header {
  align-items: center;
}
