.bf-main {
  // padding: 0.5rem;
  width: 100%;
  .bf-row {
    .bf-column {
      padding: 0 0.5rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}
.bf-invalid {
  width: 100%;
  margin-bottom: 1rem !important;
}
.p-field {
  label {
    font-weight: 500;
    // &::after{
    //   content: ": ";
    //   margin-left: 0.25rem;
    // }
  }
}

.bf-main.bf-main-readonly {
  .p-invalid-custom {
    display: none !important;
  }
  .p-disabled,
  .p-component:disabled {
    opacity: 1 !important;
  }
  .p-inputnumber-button-group {
    display: none !important;
  }
  .p-dropdown .p-dropdown-trigger {
    display: none !important;
  }
  .p-dropdown .p-dropdown-label {
    white-space: normal;
  }
  .p-inputgroup-addon:last-child,
  .p-inputgroup button:last-child,
  .p-inputgroup input:last-child,
  .p-inputgroup > .p-inputwrapper:last-child,
  .p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
    background: transparent !important;
    border-top: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
    &.custom-input-action-btn {
      background: var(--primary-color) !important;
    }
  }
  .p-inputgroup-addon:first-child,
  .p-inputgroup button:first-child,
  .p-inputgroup input:first-child,
  .p-inputgroup > .p-inputwrapper:first-child,
  .p-inputgroup > .p-inputwrapper:first-child > .p-inputtext {
    background: transparent !important;
    border-top: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;

    &.custom-input-action-btn {
      background: var(--primary-color) !important;
    }
  }
  .p-inputgroup > .p-component,
  .p-inputgroup > .p-inputwrapper > .p-inputtext,
  .p-inputgroup > .p-float-label > .p-component {
    background: transparent !important;
    border-top: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
  }
  .silder-number {
    display: none !important;
  }
  .p-inputtextarea {
    overflow: hidden !important;
  }
  .p-inputgroup button.p-button:not(.custom-input-action-btn) {
    display: none !important;
  }
}
.bf-main.bf-main-builder {
  position: relative;
  border: 1px solid;
  padding: 2.5rem 0.5rem;
  padding-bottom: 0;
  .bfb-panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .bf-row {
    position: relative;
    border: 1px solid;
    padding: 0.5rem;
    padding-bottom: 0.125rem;
    padding-top: 2rem;
    margin: 0;
    margin-bottom: 0.5rem;
  }
  .bf-column {
    position: relative;
    border: 1px solid;
    padding: 0.5rem;
    margin-bottom: 1rem;
    padding-top: 2rem;
    min-height: 3.5rem;
    margin-bottom: 0;
  }
  .bf-sub-row {
    border: 1px solid;
    min-height: 1rem;
    padding: 0.5rem;
    margin: 0;
  }
}
