@import "../../../../../style/utils";

.nwow-approval-list {
  @include sm-screen {
    padding: 0.5rem;
  }

  .header {
    flex-wrap: wrap;
  }

  .title {
    margin-bottom: 1.5rem;

    @include sm-screen {
      width: 100%;
    }
  }

  .title-desc {
    margin-top: 0.5rem;
  }

  .action-group {
    @include sm-screen {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 1.5rem;
    }
  }

  .nwow-planner-btn {
    width: 200px;
    justify-content: center;
  }

  .nwow-approval-list-datatable {
    .p-card-content {
      padding: 0;
    }

    .bt-table {
      min-height: 0 !important;
    }

    .bt-header {
      .bt-header-toolbar {
        display: none;
      }

      .animated {
        animation-delay: unset !important;
        animation-duration: unset !important;
      }
    }

    .bt-header .bt-toolbar {
      background: var(--surface-b);
      margin-top: 0;

      .bt-toolbar-content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
      }

      .current-year-container {
        padding: 1rem;
      }

      .filter-type-container {
        display: flex;
        flex-wrap: wrap;
      }

      .filter-type-dropdown {
        width: 200px;
      }

      .filter-approver-dropdown {
        width: 250px;
        margin-left: 10px;
      }

      .filter-status-dropdown {
        width: 200px;
      }

      @include sm-screen {
        .filter-type-container {
          width: 100%;
          padding-bottom: 10px;
        }

        .filter-type-dropdown {
          width: 100%;
        }

        .filter-approver-dropdown {
          width: 100%;
          margin-left: 0;
          margin-top: 5px;
        }

        .filter-status-container {
          display: flex;
          width: 100%;
        }

        .filter-status-dropdown {
          width: 100%;
        }
      }

      .bt-action {
        display: block;
      }
    }

    .p-datatable .p-datatable-tbody > tr {
      background: var(--surface-b);
    }

    .p-row-expanded {
      display: none;
    }

    .bt-table .p-datatable-scrollable-body {
      max-height: 46vh;
    }

    @include sm-screen {
      .p-row-expanded {
        display: table-row;
        background: #fff !important;
      }

      .bt-table .p-datatable .p-datatable-thead > tr > th {
        padding: 0.5rem;
      }

      .bt-table .p-datatable .p-datatable-thead > tr > th,
      .bt-table .p-datatable .p-datatable-tbody > tr > td {
        &.selection-column {
          width: 5.3em !important;
          padding: 0.5rem;
        }

        &.hris-bt-expander-column {
          padding: 0;
          width: 2rem;
        }

        &.bt-employeeName {
          width: auto !important;
        }

        &.bt-nwowPeriod {
          width: 40% !important;
        }
      }
      .bt-table .p-datatable .p-datatable-tbody > tr > td {
        .p-column-title {
          display: none;
        }

        .nwow-approval-list-mobile {
          .p-column-title {
            display: inline-block;
          }

          .p-datatable-tbody > tr > td {
            background: #fff !important;
          }
        }
      }
    }
  }

  @include sm-screen {
    .datatable-responsive {
      table:not(.mobile-expander-header-style).p-datatable-scrollable-body-table {
        .p-selection-column {
          display: table-cell;
        }
      }
    }
  }

  .footer {
    button:not(:first-child) {
      margin-left: 1rem;
    }
  }
}
