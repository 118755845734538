@import "/src/style/utils/index.scss";

.file-upload {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 2px solid var(--surface-d);

  &__header {
    background: var(--surface-d);
  }

  &__drop-zone {
    background: white;
    flex: 1;
    overflow: hidden auto;
    padding: 0.4rem 0rem;
  }

  .dragging {
    box-sizing: border-box;
    border: 3px dashed var(--surface-d);
  }

  .upload-button {
    margin: 0.5rem;
    padding: 0.5rem 2rem;
  }

  .action-button-group {
    display: flex;
    justify-content: space-between;
  }

  .remove-button {
    border-radius: 0.5rem !important;
    height: 2rem;
    width: 2rem;
    & > span.pi {
      font-size: 1.3rem !important;
    }
  }

  .download-file-button {
    border-radius: 0.5rem !important;
    height: 2rem;
    width: 2rem;
    & > span.pi {
      font-size: 1.3rem !important;
    }
  }

  .dummy-button {
    border-radius: 0.5rem !important;
    height: 2rem;
    width: 2rem;
  }

  .file-entry {
    &__meta {
      padding: 2rem 0 2rem 2rem;
    }
  }

  table > thead {
    display: none;
  }

  .file-size-column {
    width: 30%;
  }

  .action-column {
    width: 120px;
  }

  .p-datatable .p-datatable-tbody {
    & > tr > td {
      background: none !important;
      border: none !important;
      padding: 0.8rem 1.7rem;
      word-break: break-all;
    }
  }
}

@include sm-screen {
  .file-upload {
    .file-size-column {
      width: 80px;
    }

    .action-column {
      width: 100px;
    }

    .p-datatable .p-datatable-tbody {
      & > tr > td {
        background: none !important;
        border: none !important;
        padding: 0.8rem 0.8rem;
        word-break: break-all;
      }
    }
  }
}
