@import "../../../../../style/utils";

.block-leave {
  @include sm-screen {
    padding: 0.5rem;
  }

  .year-filter-container {
    background: #fff;
    display: flex;
    flex-wrap: wrap;

    .year-filter-block {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      flex: 0 1 25%;

      @include sm-screen {
        padding: 0.5rem;
        flex: 0 1 100%;
      }

      .filter-label {
        font-weight: 500;
      }

      .p-dropdown {
        width: 100%;
      }

      .leave-year-filter-value {
        height: 33px;
        display: flex;
        align-items: center;
      }
    }
  }

  .block-leave-info-container {
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;

    @include sm-screen {
      margin-bottom: 60px;
    }

    .declare-date-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      @include sm-screen {
        margin-bottom: 1rem;
      }

      .declare-date-block {
        padding: 2rem;
        display: flex;
        flex-wrap: wrap;
        flex: 0 1 25%;

        @include sm-screen {
          padding: 0.5rem;
          flex: 0 1 100%;
        }

        .date-label {
          font-weight: 500;
          width: 100%;
        }

        .date-value {
          height: 33px;
          display: flex;
          align-items: center;
        }
      }

      .base-control {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }

      .p-dropdown {
        width: 100%;

        .p-dropdown-filter-container {
          width: 90%;
        }
      }
    }

    .block-leave-info-table {
      padding: 0 2rem 2rem 2rem;

      @include sm-screen {
        padding: 0 0.5rem 0.5rem 0.5rem;

        .p-card-body {
          padding: 0;
        }
      }

      .p-card-content {
        padding: 0;
      }

      .bt-table {
        min-height: auto !important;
      }

      .bt-header {
        display: none;
      }

      .p-datatable-tbody > tr {
        background: var(--surface-b);
      }

      .p-datatable-frozen-view,
      .mobile-expander-header-style {
        .p-datatable-thead th.selection-column {
          .p-checkbox {
            display: none;
          }
        }

        .p-datatable-scrollable-body-table {
          .p-row-expanded .block-leave-application-table {
            display: none;
          }
        }
      }

      .p-row-expanded {
        .p-datatable-thead > tr > th {
          .p-column-title {
            display: block;
          }
        }

        .p-datatable-tbody > tr {
          background: #fff;
        }
      }

      .mobile-expander-header-style {
        > .p-datatable-thead > tr > th,
        > .p-datatable-tbody > tr > td {
          &.p-selection-column {
            padding: 0;
          }
          &.bt-coverPeriodFrom,
          &.bt-coverPeriodTo {
            width: auto !important;
          }
        }

        > .p-datatable-tbody > tr > td {
          &.bt-coverPeriodFrom,
          &.bt-coverPeriodTo {
            .p-column-title {
              display: none;
            }
          }
        }

        .block-leave-info-table-mobile {
          display: block;
          margin-bottom: 1rem;

          .p-datatable-thead {
            display: none;
          }

          .p-datatable-tbody > tr {
            background: var(--surface-b);

            > td {
              display: flex;
              justify-content: space-between;

              .p-column-title {
                display: block;
                font-weight: normal;
              }

              .p-column-value {
                font-weight: 500;
              }
            }
          }
        }

        .block-leave-application-table {
          .p-datatable-thead {
            display: none;
          }

          .p-datatable-tbody > tr {
            border-bottom: 1px solid #e9ecef;

            > td {
              display: flex;
              justify-content: space-between;

              .p-column-title {
                font-weight: normal;
              }
              .p-column-value {
                font-weight: 500;
              }
            }
          }
        }
      }

      .block-leave-info-table-mobile {
        display: none;
      }

      .p-paginator {
        display: none;
      }
    }
  }

  .leave-filter {
    .filter-block-employee {
      margin: 1rem;
    }
    .filter-block {
      margin: 2rem;
      @include sm-screen {
        margin: 1rem;
      }

      .base-control {
        display: flex;
        flex-direction: column;
      }

      .filter-label {
        font-size: 13px;
        color: #515151;
        margin-bottom: 0.25rem;
      }

      .filter-leave-type {
        width: 300px;
      }

      .filter-year {
        width: 220px;
      }
    }

    @include sm-screen {
      .filter-block__leave-type {
        width: 100%;

        .filter-leave-type {
          width: 100%;
        }
      }

      .filter-block__year {
        width: 100%;

        .filter-year {
          width: 100%;
        }
      }
    }

    .filter-block-employee.filter-block__self {
      padding-top: 3rem;
    }
  }

  .leave-section-wrapper {
    background: #fff;
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;

    @include sm-screen {
      &:first-child {
        margin-top: 0;
      }

      margin-top: 1.5rem;
    }
  }
}
