#admin > div > div > div > div > div.bt-header > div > div > div.panel-header {
  padding: 0;
  border: none;
  background: #fff;
  display: flex;
  justify-content: space-between;

  .multi-step-form-show-more-btn {
    background: transparent;
    color: var(--primary-color);
    min-width: 122px;
    height: 35px;
  }
}
