@import "../../style/utils";

.base_multi_step_form {
  &-group {
    background: #fff;
    margin: 2rem 1.5rem;
    margin-top: 0;
    padding: 28px;

    .title {
      font-size: 1.2rem;
      font-weight: 500;
    }

    &.horizontal {
      //help button same row as label
      .label-help {
        display: inline !important;
      }

      .bf-row {
        display: block;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }

      .bf-column {
        width: 100%;
      }
      .bf-main .bf-row .bf-column {
        margin: 0;
      }

      .base-control-readonly {
        :not(.checkbox-control) {
          > .p-inputgroup {
            > .p-multiselect > .p-multiselect-trigger {
              display: none;
            }
            > .p-dropdown {
              .p-dropdown-label.p-inputtext {
                overflow: visible;
                text-overflow: ellipsis;
                white-space: inherit;
              }
            }
            > .p-inputtext {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: inherit;
            }
          }
        }
      }

      .p-field {
        // display: flex;
        // align-items: center;
        // flex-wrap: wrap;
        // margin-bottom: 0;

        display: grid;
        grid-template-areas:
          "label component"
          "hint component";
        grid-template-columns: 80% 20%;

        & > label {
          width: 300px;

          &::after {
            content: ":";
          }
        }

        .p-inputgroup {
          flex: 1;
          min-height: 27px;
          font-size: 15px;
        }

        > :first-child {
          grid-area: label;
          width: auto !important;
        }
        > :nth-child(2) {
          &.p-inputgroup {
            grid-area: component;
            @include sm-screen {
              justify-content: flex-end;
            }
          }
        }
        > :last-child {
          &:not(.p-inputgroup) {
            margin: 0;
            grid-area: hint;
            word-break: break-word;
          }
        }

        // .p-component {
        //   display: flex;
        //   align-items: center;
        // }

        .control-hint-right {
          flex: 1;
        }
        .control-hint-bottom {
          // margin-left: 300px;
          flex-basis: 100%;
        }

        //checbox-control
        &.checkbox-control {
          display: grid;
          grid-template-areas:
            "inputGroup"
            "hint";
          grid-template-columns: 100%;

          @include sm-screen {
            .p-inputgroup {
              margin-top: 0;
            }
          }

          label {
            margin: 0 !important;
            color: #515151 !important;
            font-size: 13px;
            &::after {
              content: ":";
            }
            display: block;
          }

          > :first-child {
            grid-area: inputGroup;
          }
          > :nth-child(2) {
            &.control-hint-bottom {
              grid-area: hint;
              @include sm-screen {
                .p-inputgroup {
                  justify-content: flex-end;
                }
              }
            }
          }

          > .p-inputgroup {
            display: grid;
            grid-template-areas: "label checkbox";
            grid-template-columns: 50% 50%;

            > :first-child {
              grid-area: checkbox;
              width: 100%;
              display: flex;
              justify-content: flex-end;
            }
            > :nth-child(2) {
              grid-area: label;
              margin: 0;
              word-break: break-all;
            }

            @include sm-screen {
              grid-template-columns: auto;

              > :first-child {
                grid-area: checkbox;
                width: auto;
              }
            }
          }
        }

        &.date-control-inner {
          > :nth-child(2) {
            grid-area: component;
            @include sm-screen {
              .p-inputgroup {
                justify-content: flex-end;
              }
            }
          }
          > :nth-child(3) {
            grid-area: component;
            @include sm-screen {
              .p-inputgroup {
                justify-content: flex-end;
              }
            }
          }
        }
      }

      .p-grid {
        .bf-column {
          .base-control {
            border: 1px solid #ccc !important;
            padding: 1rem;
          }
          &:first-child {
            .base-control {
              border: 1px solid #ccc !important;
              padding: 1rem;
              border-radius: 5px 5px 0 0;
            }
          }
          &:last-child {
            .base-control {
              border: 1px solid #ccc !important;
              padding: 1rem;
              border-radius: 0 0 5px 5px;
            }
          }
          &:not(:last-child) {
            .base-control {
              border: 1px solid #ccc !important;
              border-bottom: 0 !important;
              padding: 1rem;
            }
          }
          &:only-child {
            .base-control {
              border: 1px solid #ccc !important;
              padding: 1rem;
              border-radius: 5px 5px 5px 5px;
            }
          }
        }
      }
    }
  }

  // panel
  .base_multi_step_form-panel {
    .panel-header {
      padding: 0;
      border: none;
      background: #fff;
      display: flex;
      justify-content: space-between;

      .base_multi_step_form-show-more-btn {
        background: transparent;
        color: var(--primary-color);
        min-width: 122px;
        height: 35px;
      }
    }

    .panel-content {
      padding: 0;
      border: none;
      max-height: none !important;
      overflow: hidden;
      // transition: max-height 0.2s ease-in-out;

      &.panel-content-collapsed {
        max-height: 0;
      }
    }
  }

  // control
  .bf-main {
    .custom-holder {
      &.p-lg-1,
      &.p-lg-2,
      &.p-lg-3,
      &.p-lg-4,
      &.p-lg-5,
      &.p-lg-6,
      &.p-lg-7,
      &.p-lg-8,
      &.p-lg-9,
      &.p-lg-10,
      &.p-lg-11 {
        padding-right: 0.5rem;
      }
    }

    .bf-control {
      padding: 0;
    }

    .base-control {
      & > div > label {
        margin: 0;
        color: #515151;
        font-size: 13px;
        white-space: normal;
      }

      // Error handling
      // red border
      // .p-inputgroup.p-inputgroup-error > div,
      // .p-inputgroup.p-inputgroup-error > input,
      // .p-inputgroup.p-inputgroup-error > span > input {
      //   border: 0.2rem solid red;
      // }
      .p-inputgroup {
        align-items: center;
        min-height: 27px;
      }

      &.base-control-readonly {
        .p-inputgroup {
          padding: 0.5rem;
          background-color: var(--surface-d);
        }
      }

      // Error
      .p-component.p-invalid {
        border: 0.2rem solid red;

        & .p-inputtext {
          border: none;
        }
      }

      // hide error text
      small.p-invalid-custom {
        display: none !important;
      }

      .p-field > label {
        margin-bottom: 0.25rem;
      }
      .p-field > label::before {
        content: none;
      }
      .upload-control {
        & > label {
          font-weight: 700;
          font-size: 16px;
        }
      }

      .checkbox-control {
        .p-inputgroup {
          align-items: flex-start;
        }

        .p-checkbox-label {
          white-space: normal;

          &::before {
            content: "";
          }
        }

        .p-checkbox-box.p-highlight {
          border-color: #707070;

          .p-checkbox-icon {
            width: 100%;
            height: 100%;
            border: 3px #fff solid;
            background-color: var(--primary-color);
          }

          .pi-check:before {
            content: "";
          }
        }

        // Error
        .p-inputgroup-error > .p-checkbox-label {
          color: red;
        }

        &.p-invalid {
          border: none;
        }
      }

      .label-control {
        margin: -0.25rem 0 0.25rem 0;
      }
    }
  }

  // read only
  .base-control.base-control-readonly {
    > .p-inputgroup > .p-multiselect > .p-multiselect-trigger {
      display: none;
    }

    .p-component {
      border: none;
    }

    & > div > label {
      margin: 0 !important;
      color: #515151 !important;
      font-size: 13px;
    }

    & > div > label::before {
      content: none;
    }

    .p-checkbox-label {
      white-space: normal;
      overflow: inherit;
      text-overflow: inherit;
      display: inherit;
      // margin-left: 0.5rem;
      flex: 1;
    }

    .label-control {
      padding: 0;
      color: black;
      font-size: 15px;
      display: block;
      margin: 0.25rem 0 0.25rem 0;
    }

    .p-inputtext {
      padding: 0;
      color: black;
      font-size: 15px;
    }

    // control
    & > div.upload-control > label {
      font-size: 16px;
      margin-bottom: 0.5rem;
    }

    .message-input-block {
      .p-inputtext {
        padding: 1rem;
        padding-right: 155px;
      }
    }

    .p-invalid-custom {
      display: none !important;
    }
    .p-disabled,
    .p-component:disabled {
      opacity: 1 !important;
    }
    .p-inputnumber-button-group {
      display: none !important;
    }
    .p-dropdown .p-dropdown-trigger {
      display: none !important;
    }
    .p-inputgroup-addon:last-child,
    .p-inputgroup button:last-child,
    .p-inputgroup input:last-child,
    .p-inputgroup > .p-inputwrapper:last-child,
    .p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
      background: transparent !important;
      border-top: 0 !important;
      border-right: 0 !important;
      border-left: 0 !important;
      &.custom-input-action-btn {
        background: var(--primary-color) !important;
      }
    }
    .p-inputgroup-addon:first-child,
    .p-inputgroup button:first-child,
    .p-inputgroup input:first-child,
    .p-inputgroup > .p-inputwrapper:first-child,
    .p-inputgroup > .p-inputwrapper:first-child > .p-inputtext {
      background: transparent !important;
      border-top: 0 !important;
      border-right: 0 !important;
      border-left: 0 !important;

      &.custom-input-action-btn {
        background: var(--primary-color) !important;
      }
    }
    .p-inputgroup > .p-component,
    .p-inputgroup > .p-inputwrapper > .p-inputtext,
    .p-inputgroup > .p-float-label > .p-component {
      background: transparent;
      border-top: 0 !important;
      border-right: 0 !important;
      border-left: 0 !important;
    }
    .silder-number {
      display: none !important;
    }
    .p-inputtextarea {
      overflow: hidden !important;
    }
    .p-inputgroup button.p-button:not(.custom-input-action-btn) {
      display: none !important;
    }
  }

  @include sm-screen {
    &-group {
      margin: 20px 0px;
      padding: 14px;

      .title {
        font-weight: 500;
      }

      &.horizontal {
        .p-inputgroup {
          margin-top: 1rem;
        }
        .p-field {
          & > label {
            width: 100%;
          }
          .control-hint-bottom {
            margin-left: 200px;
          }

          .control-hint-bottom {
            margin: 0;
            flex-basis: 100%;
          }

          grid-template-areas:
            "label"
            "hint"
            "component";
          grid-template-columns: 100% 100% 100%;
        }
      }
    }
  }

  .p-multiselect-label {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}

.base-scroll-top-shortcut {
  position: fixed;
  padding: 0;
  top: 0;
  right: 0;
  display: block;
  width: 550px;
  z-index: 1101;
  height: 100%;
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  transform: translateX(100%);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  .base-scroll-top-content-wrapper {
    position: relative;
    height: 100%;
    color: var(--text-color);
    background-color: var(--surface-overlay);
    .base-scroll-top-menu-shortcut-button {
      display: block;
      position: absolute;
      width: 52px;
      height: 52px;
      // line-height: 52px;
      background: var(--primary-color) !important;
      text-align: center;
      color: var(--primary-color-text) !important;
      top: 80%;
      left: -51px;
      z-index: -1;
      overflow: hidden;
      cursor: pointer;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      -webkit-animation-name: rubberBand;
      animation-name: rubberBand;
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-iteration-count: 3;
      animation-iteration-count: 3;
      -webkit-animation-delay: 5s;
      animation-delay: 5s;

      @include sm-screen {
        top: 70%;
      }
      i {
        font-size: 26px;
        line-height: inherit;
        cursor: pointer;
        transform: rotate(1turn);
        transition: transform 1s;
      }

      &:visited {
        transform: rotate(0deg);
      }
    }
  }
}
