@import "../../style/utils";

.custom-steps {
  margin: 3rem 0;
  height: 35px;

  ul {
    justify-content: center;
  }

  ul > li::before {
    border-top: none;
  }

  .p-steps-item {
    position: relative;
    display: flex;
    justify-content: center;
    flex: none;
    min-width: 160px;
  }

  .p-steps-item .p-menuitem-link {
    background: transparent;
  }

  .p-steps-item .p-menuitem-link .p-steps-number {
    color: #ffffff;
    /* border: 1px solid #e9ecef; */
    background: #8d8d8d;
  }

  .p-steps-item.p-highlight .p-steps-number {
    background: var(--primary-color);
    color: var(--primary-color-text);
  }

  &:before {
    content: " ";
    border-top: 1px solid #dee2e6;
    width: 100%;
    top: 27px;
    left: 0;
    display: block;
    position: absolute;
    margin-top: -1rem;
  }

  @include sm-screen {
    ul {
      padding: 0px 25px !important;
    }
    .p-steps-item {
      flex: 1 1 auto;
      min-width: 1px;
      height: 54px;
    }

    .p-steps-title {
      display: none;
    }
  }
}
