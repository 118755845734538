@import "../../../../style/utils";

.rental-allowance-baseTable {
  .dropdown-wrapper {
    padding: 0 0.5rem 1.5rem;

    .p-dropdown {
      width: 100%;
    }
  }

  .p-tabmenu {
    // margin-bottom: 1.75rem;
  }

  .bt-table .advanced-form {
    margin-top: 0rem !important;
  }
  .p-card-content {
    padding-top: 0;
  }
  .bt-header-toolbar {
    height: 0;
  }
  .bt-toolbar {
    margin-top: 0 !important;
    display: none !important;
  }

  .filter-container {
    // margin: 2rem 0;

    .p-dropdown {
      width: 100%;
    }
  }
}

.rental-datatable {
  .p-datatable .p-datatable-tbody > tr {
    background: var(--surface-b) !important;

    @include sm-screen {
      &.p-row-expanded {
        background: var(--surface-a) !important;
      }
    }
  }

  @include sm-screen {
    .bt-table {
      min-height: 0 !important;
    }
  }

  .p-datatable-frozen-view .p-row-expanded td div {
    display: none;
  }

  .action-column {
    text-align: center !important;
  }

  .p-datatable-infotable {
    display: none;

    @include sm-screen {
      display: block;

      .p-datatable-tbody > tr {
        background: var(--surface-a) !important;
      }
    }
  }

  .p-datatable-detailtable .p-datatable-tbody > tr {
    background: var(--surface-a) !important;

    td {
      vertical-align: top;
    }

    @include sm-screen {
      background: var(--surface-b) !important;

      .p-datatable-tbody > tr {
        background: var(--surface-a) !important;
      }
    }
  }

  .p-datatable-detailtable .p-datatable-thead .p-column-title {
    display: -webkit-box !important;
  }

  .p-datatable-detailtable .p-row-expanded td {
    padding: 0;
    border: none;
  }

  .p-datatable-mobiletable {
    display: none;

    @include sm-screen {
      display: block;

      .p-datatable-thead {
        display: none;
      }

      .p-datatable-tbody td {
        width: 100% !important;
      }
    }
  }

  .p-datatable-header {
    display: none;
  }

  .attachment-block {
    background: var(--surface-h);
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    padding: 5px 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;

    @include sm-screen {
      margin-right: 0;
    }
  }

  @include sm-screen {
    .datatable-responsive .p-datatable.p-datatable-responsive {
      table:not(.mobile-expander-header-style) .p-datatable-tbody > tr > td {
        width: 100% !important;
        max-width: 100%;

        .bt-cell {
          justify-content: space-between;

          .p-column-title {
            font-weight: normal;
          }

          .bt-cell-value {
            flex-grow: 0;
            font-weight: bold;
          }
        }
      }

      .bt-taxYear.table-expandable-header {
        width: 0 !important;
        padding: 0 !important;
      }

      .p-datatable-tbody .bt-taxYear {
        height: 0;
        padding: 0;
        overflow: hidden;
      }
    }

    .datatable-responsive {
      td.action-column {
        .p-column-title {
          display: none !important;
        }
      }
    }
  }
}

.add-new-application {
  @include lg-screen {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
    margin-top: 22px !important;
  }

  @include md-screen {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
    margin-top: 22px !important;
  }

  @include sm-screen {
    div {
      button {
        width: 100%;
      }
    }
  }
}
