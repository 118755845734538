.application-status {
  font-size: 13px;
  word-break: break-word;
  display: flex;
  align-items: center;

  &__indicator {
    height: 8px;
    width: 8px;
    min-height: 8px;
    min-width: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 6px;
    &--draft {
      background-color: #bfbfbf;
    }

    &--submitted {
      background-color: #ffff00;
    }

    &--approved {
      background-color: #92d050;
    }

    &--rejected {
      background-color: #ff0000;
    }

    &--withdraw {
      background-color: #bfbfbf;
    }

    &--completed {
      background-color: #00b050;
    }

    &--returned {
      background-color: #0000ff;
    }
  }
}
