@import "../../../../../style/utils";

.nwow-calendar-view {
  min-width: 950px;

  @include sm-screen {
    padding: 0.5rem;
    min-width: 0 !important;

    .header {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .title {
    margin-bottom: 1.5rem;
    @include sm-screen {
      width: 100%;
    }
  }
  .title-desc {
    margin-top: 0.5rem;
  }

  .main {
    background: #fefefe;
    min-height: 500px; // tmp
    padding: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    @include sm-screen {
      flex-wrap: wrap;
      padding: 0.5rem;
      margin-bottom: 60px;
    }
  }

  .main-l {
    width: 50%;
    padding: 1rem;
    @include sm-screen {
      width: 100%;
      padding: 0.5rem;
    }

    .main-l-container {
      max-width: 650px;
      margin: auto;
    }
  }

  .main-r {
    width: 50%;
    padding: 1rem;
    @include sm-screen {
      width: 100%;
      padding: 0.5rem;
    }
  }

  // block changing month in view mode
  .p-calendar.hide-arrow {
    .p-datepicker-prev,
    .p-datepicker-next {
      display: none;
    }
  }

  .legend-list {
    margin-bottom: 4rem;
    color: var(--surface-e);
    @include sm-screen {
      margin-bottom: 1.5rem;
    }

    .custom-legend {
      margin: 0.5rem 0;

      &.statutory-legend {
        font-weight: 500;
        color: var(--table-row-active);
      }
    }
  }

  .section-title {
    font-size: 1rem;
    font-weight: 700;
    margin: 1rem 0rem;
  }

  .calendar-app-status-indicator {
    font-size: 5px;
    height: 5px;
    width: 5px;
    display: flex;
    margin: auto;
  }

  .no-nwow-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    opacity: 0.5;
  }

  // control
  .nwow-application-meta {
    .base-control {
      label {
        opacity: 0.6;
        margin: 0;
      }

      .p-inputgroup {
        .p-inputtext {
          font-weight: 600;
          padding: 9px 11px;
        }
      }
    }

    .base-control.base-control-readonly {
      .p-inputgroup .p-inputtext {
        border: none;
        padding: 3px;
      }
    }

    .nwow-workflow-btn {
      margin-left: 5px;
      font-weight: 500;
      color: var(--primary-color);
      cursor: pointer;
    }
  }

  .nwow-application-detail {
    .base-control {
      label {
        opacity: 0.6;
        margin: 0;
      }

      .p-inputgroup {
        .p-inputtext {
          font-weight: 600;
          padding: 9px 11px;
        }
      }

      .readonly,
      input[readOnly],
      textarea[readOnly],
      .p-dropdown.p-disabled {
        border-top: none;
        border-left: none;
        border-right: none;
        background: var(--surface-c);
        min-height: 38px;
      }
    }

    .nwow-application-balance {
      .app-balance-desc {
        opacity: 0.6;

        & > span {
          cursor: pointer;
        }
      }

      .app-balance {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        font-weight: 700;
        color: var(--text-color);
      }
    }
  }

  .nwow-workflow {
    .nwow-workflow-title {
      margin-bottom: 1rem;
      font-size: 16px;
      font-weight: 500;
      @include sm-screen {
        width: 100%;
      }
    }

    .nwow-workflow-tabmenu {
      margin-bottom: 0.5rem;

      @include sm-screen {
        width: 100%;

        li > a.p-menuitem-link {
          padding: 0.6rem;
        }
      }
    }

    .nwow-workflow-header-table {
      .p-datatable-thead > tr > th,
      .p-datatable-tbody > tr > td {
        padding: 0.5rem;
      }

      .workflow-expander-end {
        max-width: 0;
        padding: 0 !important;

        button {
          display: none;
        }
      }

      @include sm-screen {
        .p-datatable-thead {
          display: none;
        }

        .p-datatable-tbody > tr {
          display: flex;
          flex-wrap: wrap;
          border-bottom: 1px solid #e9ecef;

          > td {
            width: 100% !important;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            border: none;

            .p-column-title {
              display: block;
            }
            .p-column-value {
              font-weight: 500;
            }
          }
        }

        .p-datatable-tbody .p-row-expanded > td {
          flex-wrap: wrap;
        }

        .workflow-expander-start {
          display: none !important;
        }
        .workflow-expander-end {
          padding: 0.2rem !important;
          max-width: none;

          button {
            display: inline-flex;
          }
        }
      }
    }

    .nwow-workflow-detail-table,
    .nwow-workflow-content-table {
      .p-datatable-thead > tr > th .p-column-title {
        display: block;
      }
    }
  }
}
