@import "../../../style/utils";
.array-control-table {
  // .p-datatable-tbody {
  //   tr {
  //     &:first-child {
  //       padding: 0;
  //       td {
  //         padding-bottom: 1.5rem;
  //         .base-control {
  //           .p-field {
  //             margin-bottom: 0rem;
  //           }
  //           label {
  //             display: none;
  //           }
  //           margin-left: -0.75rem;
  //         }
  //       }
  //     }
  //   }
  // }
  @include sm-screen {
    .p-datatable-thead {
      display: none;
    }
    tr {
      border: 1px solid #e9ecef;
      > td {
        text-align: left;
        width: calc(100vw - 3rem) !important;
        float: left;
        clear: left;
        border: 0 none !important;
        > div.table-expandable-row {
          display: flex;
          flex-wrap: wrap;
          .p-column-title {
            padding: 0.4em;
            display: inline-block;
            margin: -0.4em 0.25em -0.4em -0.4rem;
            font-weight: bold;
          }
        }
      }
    }
  }
}

// .base_multi_step_form-group.horizontal .array-control-inner {
//   td > div.base-control > div.p-field {
//     .p-field {
//       grid-template-columns: 0% 100% !important;
//     }
//     @include sm-screen {
//       .p-field {
//         grid-template-columns: 100% 100% 100% !important;
//       }
//     }
//   }
// }

.array-control-inner {
  > .p-inputgroup.p-inputgroup-error {
    .base-control {
      .p-inputgroup {
        border: 0.2rem solid red;
      }
    }
  }
  .p-column-title {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  > .p-inputgroup {
    display: flex;
    flex-direction: column;

    //toolbar
    > div {
      width: 100%;
      > .p-toolbar {
        padding: 0;
        width: 100%;
        flex-wrap: wrap;
        > div > button > .p-button-label {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          width: 1%;
          text-overflow: ellipsis;
          cursor: pointer;
        }
      }
    }
  }
}
