@import "../../../../style/utils/index.scss";
.comp-loading {
  width: 100%;
  text-align: center;
  position: absolute;
  z-index: 999;
  background: var(--overlay-a);
  top: 0;
  left: 0;
  height: 100%;
  padding-top: 25%;
}
.c-page-body {
  .c-setting-tree {
    padding: 0;
    border: 0;
    background: transparent;
    .p-treenode-content {
      background-color: var(--surface-a);
      font-weight: 500;
      margin-left: 0.5rem;
      position: relative;
      &::before {
        content: "";
        background-color: var(--surface-c);
        position: absolute;
        width: 0.5rem;
        top: 0;
        left: -0.5rem;
        height: 100%;
      }
      &:hover,
      &.p-highlight {
        &::before {
          background-color: var(--primary-color);
        }
      }
      .p-treenode-label {
        white-space: pre-wrap;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .p-tree-toggler {
        width: 0rem !important;
      }
    }
  }
  .c-group-title {
    background-color: var(--surface-e);
    color: var(--primary-color-text);
    font-weight: bold;
    padding: 1rem;
    position: relative;

    .c-group-tile-action {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      bottom: 0;
      .p-button {
        height: 100%;
      }
    }
  }
  .c-group-sub-title {
    cursor: pointer;
    &.inline-tile {
      margin-left: -1rem;
      margin-right: -1rem;
      &.inline-tile-first {
        margin-top: -1rem;
      }
    }
    background-color: var(--surface-d);
    border-top: 1px solid var(--primary-color-text);
    font-weight: bold;
    padding: 1rem;
    position: relative;
    .c-group-sub-tile-action {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      bottom: 0;
      .p-button {
        height: 100%;
      }
    }
  }

  .ep-tabs-true {
    display: none;
  }

  .ep-tabs {
    .p-grid > .p-col,
    .p-grid > [class*="p-col"] {
      padding-left: 20px;
      padding-right: 20px;
    }
    .p-grid > .p-col:first-child,
    .p-grid > [class*="p-col"]:first-child {
      padding-right: 20px;
    }
    .p-grid > .p-col:last-child,
    .p-grid > [class*="p-col"]:last-child {
      padding-left: 20px;
    }
  }

  .ep-tabs-false {
    .p-grid > .p-col,
    .p-grid > [class*="p-col"] {
      padding-left: 20px;
      padding-right: 20px;
    }
    .p-grid > .p-col:first-child,
    .p-grid > [class*="p-col"]:first-child {
      padding-right: 20px;
    }
    .p-grid > .p-col:last-child,
    .p-grid > [class*="p-col"]:last-child {
      padding-left: 20px;
    }
  }

  .c-group-panel-section-data-array {
    transition: all 0.4s;
    &.hide-panel {
      height: 0 !important;
      overflow: hidden;
      margin: 0;
      padding: 0;
    }
    &.c-group-panel-no-padding {
      padding: 0;
    }
    &.c-group-panel-no-margin {
      margin: 0;
    }
    margin-bottom: 1rem;
    padding: 1rem 0rem 1rem 0rem;
    background-color: var(--surface-a);
    .c-avatar-thumb {
      max-width: 122px;
      border-radius: 50%;
    }
    .c-item-main {
      margin-bottom: 2rem;
      .c-item-main-desc {
        font-weight: normal;
        font-size: 1rem;
      }
      font-weight: bold;
      font-size: 1.5rem;
    }
    .p-card-title {
      color: var(--primary-color);
    }
  }

  .c-group-panel {
    transition: all 0.4s;
    &.hide-panel {
      height: 0 !important;
      overflow: hidden;
      margin: 0;
      padding: 0;
    }
    &.c-group-panel-no-padding {
      padding: 0;
    }
    &.c-group-panel-no-margin {
      margin: 0;
    }
    margin-bottom: 1rem;
    padding: 1rem 1rem 0rem 1rem;
    background-color: var(--surface-a);

    .c-avatar-container {
      width: 8.6rem;
      text-align: center;
    }

    .c-avatar-container-breakpoint {
      width: 5.7rem;
      text-align: center;
    }

    .c-avatar-thumb {
      width: 8.6rem;
      height: 8.6rem;
      border-radius: 50%;
      object-fit: cover;
      object-position: 0% 0%;
    }

    .c-avatar-thumb-breakpoint {
      width: 5.7rem;
      height: 5.7rem;
      border-radius: 50%;
      object-fit: cover;
      object-position: 0% 0%;
    }

    .c-u-details-container {
      margin-left: 2rem;
    }

    .c-u-details-container-breakpoint {
      margin-left: 1rem;
    }

    .c-item-main {
      // margin-bottom: 2rem;
      font-weight: bold;
      // font-size: 1.5rem;
      font-size: 24px;
      // word-break: break-all;
    }

    .c-item-main-status {
      display: inline-block;
      padding: 0.5rem;
      margin: 0.5rem 0;
      border-radius: 25px;
      color: var(--active-highlight-primary);
      background-color: var(--active-highlight-secondary);
      min-width: 82px;
      height: 30px;
      text-align: center;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }

    .c-item-main-desc {
      margin: 0.3rem 0;
      font-weight: normal;
      font-size: 12px;
      word-break: break-all;
      .pi {
        font-size: 12px;
      }
    }

    .p-card-title {
      color: var(--primary-color);
    }

    .bf-main {
      padding: 0;
    }
  }
  .c-photo-upload {
    display: flex;
    align-items: center;
    .c-display-avatar {
      margin-right: 2rem;
      width: 15rem;
      min-height: 4rem;
      background-color: var(--surface-c);
      .c-avatar-thumb {
        max-width: 100%;
      }
    }
    .c-file-name {
      margin-right: 2rem;
    }
    .c-action-group {
      display: inline-flex;
      .p-button {
        margin-left: 0.5rem;
      }
      .p-fileupload-choose {
        background-color: var(--surface-f);
        border-color: var(--surface-f);
      }
    }
  }
}
.c-timeline-item {
  display: flex;
  font-weight: 500;
  align-items: center;
  border-bottom: 1px solid var(--surface-d);
  padding: 0.5rem 1rem;
  .c-timeline-item-title {
    flex-grow: 1;
  }
  .c-timeline-item-event {
    width: 8rem;
  }
  .c-timeline-item-time {
    width: 5rem;
  }
  .c-timeline-item-action {
    width: 2rem;
  }
}

.c-accordion-tab {
  display: flex;
  width: 100%;
  .c-accordion-tab-action {
    display: flex;
    .p-button {
      margin-right: 0.5rem;
    }

    .p-fileupload-choose {
      background-color: var(--surface-f);
      border-color: var(--surface-f);
    }
  }
}
.hidden {
  display: none;
}

.ep-dropdown {
  width: 14rem;
}

.ep-language-toogle {
  justify-content: space-between;
  padding: 0 !important;
  display: flex;
  margin: 0em;
  align-items: center;
  .ep-language-toogle-button {
    overflow: hidden;
    position: relative;
    padding: 1rem;
  }
  .p-field {
    margin: 0em;
    .p-inputgroup {
      button {
        span {
          color: var(--primary-color-text);
        }
      }
    }
    label {
      display: none;
      margin: 0em;
    }
  }
  p {
    // margin: 0em;
    padding-left: 1rem;
  }
}

.ep-emp-status-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  .ep-item-contract {
    flex-grow: 1;
  }

  .ep-item-status {
    width: 9rem;
  }

  .ep-item-date {
    width: 8rem;
  }
  .ep-item-tool {
    width: 2rem;
  }
}
.profile-tab-form {
  margin-top: 1rem;
}
.profile-tab-panel {
  overflow-x: hidden;
  .p-tabview {
    .p-tabview-nav {
      background: none;
      flex-wrap: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
      border: 0;
      li {
        flex: 1;
        justify-content: center;
      }
      .p-tabview-title {
        width: max-content;
        // align-items: flex-end;
        // white-space: pre-wrap;
        // display: -webkit-box;
        // -webkit-line-clamp: 2;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
      }
      .p-tabview-nav-link {
        height: 100%;
        background: none !important;
        justify-content: center;
      }
      li.p-highlight .p-tabview-nav-link {
        border-bottom-width: thick;
        background-color: none !important;
      }
    }

    .p-tabview-panels {
      // display: contents;

      // margin-top: 1rem;
      // background: none;
      padding: 0;
    }
  }
}

.mobile-profile {
  @include sm-screen {
    display: none;
  }

  font-size: 12px;
}
