.error-500-main-bg {
  background-color: var(--surface-a);
  color: var(--error-highlight);
  font-family: IBM Plex Sans;
  font-weight: bold;

  margin: 0.5rem;
  margin-top: 1.5rem;
  padding: 0.5rem;
  height: 500px;

  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.error-500-main-title {
  font-size: 72px;
  display: block;
}

.error-500-main-subtitle {
  font-size: 36px;
  display: block;
}
