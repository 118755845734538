.custom-legend {
  display: flex;
  align-items: center;

  .legend-icon {
    margin-right: 0.5rem;
    max-height: 15px;
    max-width: 15px;
  }

  .legend-square {
    width: 15px;
    height: 15px;
  }

  .legend-round {
    font-size: 7px;
  }

  .legend-text {
    font-weight: 500;
  }
}
