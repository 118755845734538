.comment-chatroom-container {
  background: var(--surface-c);
  margin: 10px;
  position: relative;

  .comment-block {
    min-height: 200px;
    max-height: 300px;
    overflow-y: scroll;
  }

  .content-block {
    width: 100%;
    display: flex;

    &__center {
      justify-content: center;
    }
    &__left {
      justify-content: flex-start;
    }
    &__right {
      justify-content: flex-end;
    }
  }

  .date-block {
    background: var(--surface-a);
    color: var(--text-color-secondary);
    padding: 10px;
    margin: 3px;
  }

  .message-block {
    padding: 10px 30px;

    .sender-initial-block {
      color: var(--primary-color);
      background: var(--surface-h);
      width: 50px;
      min-width: 50px;
      height: 50px;
      border-radius: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 25px;

      .sender-initial {
        position: relative;
        top: -2px;
        font-weight: 500;
        font-size: 1.5rem;
      }
    }

    .message-bubble-block {
      padding: 15px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      position: relative;
      max-width: 80%;

      &:before {
        content: " ";
        width: 15px;
        height: 15px;
        position: absolute;
        top: 0;
      }

      &__received {
        background: var(--surface-a);
        border-top-right-radius: 15px;
        &:before {
          background: var(--surface-a);
          left: -2px;
          transform: skew(60deg);
        }
      }
      &__sent {
        background: #ffe7e7;
        border-top-left-radius: 15px;
        &:before {
          background: #ffe7e7;
          right: -2px;
          transform: skew(-60deg);
        }
      }

      .message-info {
        display: flex;
        align-items: center;

        .sender-name {
          color: var(--primary-color);
          font-weight: 500;
          margin-right: 20px;
        }

        .message-time {
          color: var(--text-color-secondary);
          font-size: 0.875rem;
        }
      }

      .message-content {
        margin-top: 3px;
        word-break: break-word;
      }
    }
  }

  .message-input-block {
    width: 100%;
    padding: 10px;
    position: relative;

    .message-input-wrapper {
      // background: var(--surface-a);
      // border-radius: 8px;

      &__readOnly {
        background: var(--surface-d);
        border-radius: 8px;
      }
    }

    .message-input {
      width: 100%;
      font-size: 1.125rem;
      padding: 1rem;
      padding-right: 155px;
      border: none;
      background: var(--surface-a);
      border-radius: 8px;
    }

    .send-message-btn {
      position: absolute;
      right: 30px;
      top: 17px;

      &__readOnly {
        background: #8d8d8d;
      }
    }

    .placeholder-btn {
      display: none !important;
    }
  }
}
