@import "../../../../../style/utils/";

.rental-change {
  .base-control {
    min-width: 50px;
  }

  .footer {
    justify-content: space-between;
  }

  @include sm-screen {
    .footer {
      padding: 0 10px;
      .next-button {
        margin: 0;
        flex: 1;
      }
    }
  }
}
