@import "../../style/utils/";

.sbf-main {
  & > div:first-child {
    padding: 1.25rem 1.25rem;
  }

  .p-panel.p-panel-toggleable .p-panel-header {
    padding: 1.25rem 1.25rem;
    button {
      display: none;
    }
  }

  .sbf-panel {
    width: 100%;
  }

  .sfb-panel-header {
    .p-field {
      margin-bottom: 0;
    }

    .p-inputgroup {
      display: flex;
      align-items: center;
    }

    .base-control > .checkbox-control > .p-inputgroup {
      align-items: center;
    }

    .p-checkbox-box.p-highlight {
      border-color: #707070;

      .p-checkbox-icon {
        width: 100%;
        height: 100%;
        border: 3px #fff solid;
      }

      .pi-check:before {
        content: "";
      }
    }
  }

  .sbf-row {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    margin: 1.25rem 0;
  }

  .sbf-org-value-col {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .sbf-change-icon {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  &.sbf-main-readonly {
    .sbf-row {
      margin: 0;
    }

    .p-panel.p-panel-toggleable .p-panel-header {
      .p-component {
        display: none;
      }
    }

    .bf-control {
      padding-left: 0.5rem;
    }

    .upload-control {
      td {
        background-color: transparent !important;
        padding: 5px;
      }

      th {
        background-color: transparent !important;
        padding: 5px;
      }

      .control-hint-bottom {
        display: none;
      }
    }

    .sbf-update-col-dummy {
      display: none;
    }
  }

  @include sm-screen {
    .sbf-update-col-dummy {
      display: none;
    }

    .bf-control.with-sbf-update-col-dummy {
      width: 100%;
    }
  }
}
