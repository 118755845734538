.kendo-editor-control-inner {
  .control-hint-bottom {
    display: inline-block;
    margin-top: 0.25rem;
  }

  .control-hint-right {
    display: flex;
    align-items: center;
    margin: 0 0 0 1rem;
  }

  &.p-field {
    margin-bottom: 0.5rem;
    & > label {
      margin-bottom: 0rem;
    }
  }

  .k-button-solid-primary {
    background-color: var(--primary-color);
    color: var(-surface-a);
    background-color: var(--primary-color);
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
  }

  .k-button-solid-base.k-selected {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
  }
  .p-invalid {
    .k-editor-content {
      border: 0.2rem solid red;
    }
  }
}

div.k-widget.k-window {
  .k-dialog-titlebar {
    color: var(-surface-a);
    background-color: var(--primary-color);
  }

  .k-upload-button {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
    color: white;
  }

  .k-button-solid-primary {
    border-color: var(--primary-color);
    color: white;
    background-color: var(--primary-color);
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
  }

  .k-tabstrip-items-wrapper .k-item[aria-selected="false"] {
    color: var(--primary-color);
  }
  .k-button-flat-primary {
    color: var(--primary-color);
  }
}

.k-list-item.k-selected,
.k-selected.k-list-optionlabel {
  background-color: var(--primary-color);
}

.k-ct-popup .k-state-selected {
  background-color: var(--primary-color);
}

/*#region editor - upload file component*/
.k-upload-status.k-upload-status-total {
  display: none; //TODO: remove progress due to saveUrl is not available
}

.k-upload-pct {
  display: none; //TODO: remove progress due to saveUrl is not available
}

.k-file-name-size-wrapper > .k-file-validation-message.k-text-error {
  display: none; //TODO: remove progress due to saveUrl is not available
}
/*#endregion editor - upload file component*/
