.chips-inner {
  .p-inputgroup.p-inputgroup-error > {
    border: 0.2rem solid red;
  }
  .p-inputgroup {
    .p-inputtext.p-chips-multiple-container.p-disabled {
      border-bottom: 0 !important;
    }
  }
  .p-chips-token {
    background: var(--select-option-primary) !important;
    border-radius: 5px !important;
  }
}
