.base-control {
  .label-help {
    position: relative;
    left: 0.25rem;
    padding: 0 !important;
    width: unset !important;
    height: unset !important;
    padding: 0 !important;
  }
  width: 100%;
  .no-label {
    margin-bottom: 0.5rem;
    label {
      display: none;
    }
  }
  label {
    &::before {
      content: "i";
      color: transparent;
    }
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: normal;
  }
  .p-dropdown {
    background: var(--surface-c);
    border: 1px solid transparent;
    border-bottom: 1px solid var(--surface-d);
  }
  input.p-inputtext {
    background: var(--surface-c);
    border: 1px solid transparent;
    border-bottom: 1px solid var(--surface-d);
  }
  span.p-inputnumber-button-group {
    border-bottom: 1px solid var(--surface-d);
  }

  //chips
  ul.p-inputtext {
    background: var(--surface-c);
    border: 1px solid transparent;
    border-bottom: 1px solid var(--surface-d);
  }
  textarea.p-inputtext {
    background: var(--surface-c);
    border: 1px solid transparent;
    border-bottom: 1px solid var(--surface-d);
  }
  .p-multiselect {
    background: var(--surface-c);
    border: 1px solid transparent;
    border-bottom: 1px solid var(--surface-d);
  }
  &.base-control-readonly {
    position: relative;
    [readonly],
    .p-disabled {
      user-select: none;
      background-color: var(--surface-d);
      text-overflow: ellipsis;
      border: 1px solid var(--surface-d);
      opacity: 1 !important;
      &.checkbox-control {
        border: none !important;
      }
    }
  }
  &.base-control-hidden {
    display: none;
  }
}
.styled-readonly {
  .base-control {
    &.base-control-readonly {
      margin-left: 1rem;
      position: relative;
      [placeholder] {
        text-overflow: ellipsis;
      }
      label {
        &::after {
          content: ":";
        }
      }
      [readonly],
      .readonly {
        background: transparent !important;
        padding: 0.125rem;
        border: 1px solid transparent !important;
        color: var(--primary-color);
        font-weight: bold;
        text-overflow: ellipsis;
      }
      &::after {
        content: "";
        top: 0;
        left: -1.25rem;
        width: 0.25rem;
        height: 100%;
        background-color: var(--surface-c);
        position: absolute;
      }
    }
  }
}
.menu-option {
  div {
    max-width: 26rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
