.leave-status {
  font-size: 13px;
  word-break: break-word;
  display: flex;
  align-items: baseline;

  &__indicator {
    height: 8px;
    width: 8px;
    min-height: 8px;
    min-width: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 6px;
    &--draft {
      background-color: #bfbfbf;
    }

    &--pending-for-approval {
      background-color: #ffb100;
    }

    &--cancelled {
      background-color: #bfbfbf;
    }

    &--approved {
      background-color: #00b050;
    }

    &--rejected {
      background-color: #ff0000;
    }
  }
}
