.cp {
  background-color: var(--surface-a);
  padding: 29px;
  .cp-title {
    // font-weight: normal;
    // font-size: 20px;
    margin-bottom: 45px;
    // font-family: var(--font-family-sidebar);
  }

  .cp-form {
    .p-button {
      background: var(--primary-color) !important;
      border: 1px solid var(--primary-color) !important;
    }
  }
}

.re-login-dialog > .p-dialog-footer > .carbon-btn-group-full {
  justify-content: center;
  > div {
    flex-grow: 0 !important;
  }
}
