.tree-table-control-inner {
  .control-hint-bottom {
    display: inline-block;
    margin-top: 0.25rem;
  }

  .control-hint-right {
    display: flex;
    align-items: center;
    margin: 0 0 0 1rem;
  }
  .p-treetable.p-component.p-invalid {
    border: none !important;
  }
  .p-invalid {
    > td {
      div {
        border-color: #f44336;
      }
    }
  }

  .tree-table-row:hover {
    color: var(--surface-f);
    background: var(--surface-g);
  }

  .p-invalid:hover {
    color: var(--surface-f);
    background: var(--surface-g);
  }

  .p-treetable .p-treetable-thead > tr > th {
    color: var(--surface-f);
    background: var(--surface-g);
  }
}
