@import "../../../style/utils";
.base_multi_step_form-group.horizontal .inputgroup-inner {
  > .p-inputgroup {
    align-content: center;
    display: grid;
    grid-template-columns: 50% 50% !important;
    > .base-control {
      height: 100%;
      align-content: center;
      display: grid;
      > div {
        overflow: hidden;
      }
      .p-field {
        grid-template-columns: 0% 100% !important;
      }
      @include sm-screen {
        .p-field {
          grid-template-columns: 100% 100% 100% !important;
        }
      }
    }
  }
}

.inputgroup-inner {
  > .p-inputgroup.p-inputgroup-error {
    > .base-control {
      .p-inputgroup {
        border: 0.2rem solid red;
      }
    }
  }
}
