@import "../../../../../style/utils/";

.rental-view {
  .rental-tabmenu {
    display: none;
    margin: 20px 0;

    @include sm-screen {
      display: block;
      -ms-overflow-style: none;
      scrollbar-width: none;
      overflow-x: scroll;
    }

    .p-tabmenu-nav {
      width: max-content;
      min-width: 100%;
    }

    .p-tabmenuitem {
      height: 42px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @include sm-screen {
    .rental-tabmenu {
      display: block;
      -ms-overflow-style: none;
      scrollbar-width: none;
      overflow-x: scroll;
    }

    .footer {
      padding: 0rem 1rem;
      .right-button-group {
        flex: 1 1 auto;
        display: flex;
      }

      .p-button {
        flex: 1;
      }
    }
  }

  @media print {
    .form-date-readyonly-value {
      display: flex !important;
      min-height: 27px;
      align-items: center;
      font-size: 1.075rem;
    }
  }
}
