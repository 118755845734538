.nwow-view-application {
  .header {
    margin-bottom: 1rem;
  }

  .custom-calendar .p-datepicker table {
    cursor: default;
  }

  .custom-calendar .p-datepicker table td > span {
    cursor: default;
  }

  .custom-calendar .p-datepicker table td > span:not(.p-highlight):hover {
    background: none !important;
    color: inherit;
    cursor: default;
  }

  .custom-calendar
    .p-datepicker
    table
    td.p-datepicker-today
    > span:not(.p-highlight):hover {
    background: var(--table-row-active) !important;
    color: inherit;
    cursor: default;
  }
}
