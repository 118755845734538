@import "../../../style/utils/";

.ep-datatable-fw {
  .p-inputtext {
    max-width: 100%;
  }
}

.ep-payslips-list-toolbar-rightcontent {
  padding: 0em;
}

.document {
  .p-row-expanded > td > .p-card {
    box-shadow: none !important;
    > .p-card-body {
      padding: 0;
      > .p-card-content {
        padding: 0;
        > div {
          min-height: auto !important;
          > .bt-header {
            display: none;
          }
          > .datatable-responsive {
            > div {
              .bt-row {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                > .p-column-title {
                  // font-weight: normal;
                  padding: 0;
                  margin: 0;
                }
                > .bt-cell-value {
                  flex-grow: 0;
                }
              }
              > .p-paginator-bottom {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .my-document-form {
    .p-button.p-component.base_multi_step_form-show-more-btn {
      display: none !important;
    }
    div.bf-control.height-auto.custom-holder
    {
      .p-inputtextarea{
        overflow: scroll !important;
        height: auto !important;
      }
    }
  
    div.bf-control.loading.custom-holder {
      .p-inputgroup {
        content: "";
        animation: blinker 1s infinite;
        animation-direction: alternate;
      }
    }
    
    @keyframes blinker {
      0% {
         opacity: 50%;
         transform: translateX(0);
      }
      50% {
         opacity: 80%;
         transform: translateX(0);
      }
      100% {
         opacity: 1;
         transform: translateX(0);
      }
    }
  
    .footer {
      background-color: var(--surface-d);
      height: 60px;
      margin: -1.8rem -1.6rem;
      padding: 0rem 1.6rem;
      width: calc(100vw - 6rem);
      position: fixed;
      bottom: 25px;
      right: 21px;
      z-index: 1000;
  
      @include sm-screen {
        width: calc(100vw);
        position: fixed;
        right: 22px;
        display: grid;
        grid-template-columns: 30% 30% !important;
      }
  
      @include md-screen {
        left: 107.2px;
      }
  
      .secondary {
        color: var(--secondary-color-text);
        border-color: var(--secondary-color-text);
      }
  
      .p-button {
        min-width: 70px;
        display: inline;
      }
  
      .next-button {
        margin-left: 1rem;
      }
  
      button.p-disabled.p-button-loading {
        width: auto;
        display: flex;
        flex-direction: row-reverse;
  
        .p-button-icon.p-button-loading-icon {
          margin: auto
        }
  
      }
    }
  }
  .datatable-responsive
    .p-datatable.p-datatable-responsive
    table:not(.mobile-expander-header-style)
    .p-datatable-tbody
    > tr
    > td {
    @include sm-screen {
      width: calc(100vw - 4rem) !important;
      text-align: right;
      .bt-cell-value {
        margin-left: 1rem;
      }
    }
  }

  div.p-datatable-scrollable-wrapper
    > div.p-datatable-scrollable-view.p-datatable-frozen-view
    > div.p-datatable-scrollable-body
    > table
    > tbody
    > tr:not(.p-rowgroup-header)
    > td
    > div.bt-row {
    width: 15rem;
  }
}
