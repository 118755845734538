@import "./reponsive/index";
@import "./utils/index";
//@import "/animate.css/animate.min.css";

.hidden {
  display: none;
}

html {
  font-size: 14px;
  font-family: var(--font-family);
  height: 100%;
}

body {
  background: var(--surface-b);
  height: 100%;
  // overflow: hidden;
  // margin: 0;
  // color: var(--text-color);
}
#root {
  display: flex;
  flex-flow: column;
  height: 100%;
}
.p-grid-fullpage {
  margin: 0 !important;
}
.p-invalid-custom {
  position: relative; //aw
}
.carbon-btn-group-holder {
  width: 100%;
  text-align: right;
  height: 3.5rem;
  .carbon-btn-group {
    display: flex;
    float: right;
    width: 20rem;
    .p-button {
      min-width: 10rem;
      margin: 0 !important;
      height: 3.5rem;
    }
  }
}
.carbon-btn-group-full {
  display: flex;
  float: right;
  width: 100%;
  .p-button {
    width: 50% !important;
    margin: 0 !important;
    height: 3.5rem;
  }
}

::placeholder {
  text-overflow: ellipsis;
}
[placeholder] {
  text-overflow: ellipsis;
}

// Scroll bar
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

// /* Track */
// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px var(--text-color-secondary) !important;
// }

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color) !important;
  border-radius: 5px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color) !important;
}
