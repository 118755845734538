@import "../../../style/utils/";

.nwow-container {
  padding: 0.5rem 3rem;

  .header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .p-button {
      padding: 0.5rem 2.5rem;
    }
  }
  .title {
    font-size: 21px;
    margin-bottom: 1.5rem;
  }
  .title-desc {
    font-size: 15px;
    color: #515151;
  }

  .footer {
    background-color: var(--surface-d);
    height: 60px;
    margin: -1.8rem -1.6rem;
    padding: 0rem 1.6rem;
    width: calc(100vw - 6rem);
    position: fixed;
    bottom: 25px;
    right: 21px;
    z-index: 1000;

    @include md-screen {
      left: 107.2px;
    }

    @include sm-screen {
      z-index: 0;
    }

    .secondary {
      color: var(--secondary-color-text);
      border-color: var(--secondary-color-text);
    }

    .p-button {
      min-width: 90px;
      display: inline;
      font-weight: 500;
    }

    .next-button {
      margin-left: 1rem;
    }

    .left-button-group,
    .right-button-group {
      .p-button:not(:last-child) {
        margin-right: 0.75rem;
      }
    }
  }

  @include sm-screen {
    .footer {
      width: calc(100vw);
      position: fixed;
      // bottom: 82px;
      right: 22px;

      // .back-button {
      //   display: none;
      // }

      .p-button {
        height: 44px;
        // text-align: start;
      }
    }
  }
}

.nwow-application-form {
  .control-hint-bottom {
    padding-left: 4px;
    color: var(--text-color-secondary);
  }

  .p-component.p-invalid {
    border: 0.2rem solid red;

    & .p-inputtext {
      border: none;
    }
  }

  .p-invalid-custom {
    display: none !important;
  }

  .note {
    font-size: 13px;
    color: var(--text-color-secondary);
    margin-top: -1.5rem;
    margin-bottom: 0.75rem;

    ol {
      margin: 0px;
      padding-left: 1rem;
    }
  }

  .acceptance {
    font-size: 8px;
    color: red;
    font-weight: bold;
  }

  .base-control {
    label {
      opacity: 0.6;
      margin: 0;
    }

    .p-inputgroup {
      .p-inputtext {
        font-weight: 600;
        padding: 9px 11px;
      }
    }

    .readonly,
    input[readOnly],
    textarea[readOnly],
    .p-dropdown.p-disabled {
      border-top: none;
      border-left: none;
      border-right: none;
      background: var(--surface-c);
      min-height: 38px;
    }
  }

  .nwow-application-balance {
    .app-balance-desc {
      opacity: 0.6;
    }

    .app-balance {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
      font-weight: 700;
      color: var(--text-color);
    }
  }
}
