@import "../../../../../style/utils";

.rental-allowance {
  .p-card .p-card-content {
    padding: 0;
  }
  .rental-header {
    padding: 0 0.5rem 1.5rem 0.5rem;
    display: flex;
    flex-wrap: wrap;
  }

  .title-group {
    display: flex;
    align-items: center;

    h2 {
      font-weight: 400;
      margin: 0;
    }

    .title {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2rem;
    }
    .desc {
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.4rem;
    }
  }

  .action-group {
    .p-dropdown {
      min-width: 16rem;
      height: 3rem;
      .p-dropdown-label.p-placeholder {
        display: inline-flex;
        align-items: center;
        padding: 7px 15px;
      }
    }

    .new-app-btn {
      justify-content: center;
    }
  }

  @include sm-screen {
    .rental-header {
      padding: 0rem 0.5rem;
    }

    .action-group {
      width: 100%;
      margin-top: 20px;

      .p-dropdown {
        // width: 100%;
        display: none;
      }

      .new-app-btn {
        // width: 100%;
        display: none;
      }
    }
  }
}

#bt-table-BaseTable_default_id
  > div.bt-header
  > div.animated.slideInUp
  > div
  > div:nth-child(2)
  > div {
  display: none;
}
