@import "../../../../../style/utils/";

.rental-view-history {
  .header {
    margin: 0;

    .page-title {
      padding: 0 0.5rem;

      h2 {
        font-weight: 400;
        margin: 0;
      }
    }
  }

  .view-history-table-container {
    background: #fff;
    margin: 2rem 0;

    .p-card-body {
      padding: 1.5rem;
    }

    .p-card-content {
      padding: 0;
    }

    .bt-table {
      .bt-header {
        .bt-title {
          padding-bottom: 0.5rem;
          font-weight: 400;
        }

        .bt-toolbar {
          display: none;
        }
      }

      .p-datatable .p-datatable-tbody > tr {
        background: var(--surface-b) !important;
      }

      th.action-column,
      td.action-column {
        text-align: center;
      }

      .action-column .p-button {
        width: auto;
        padding: 0.35rem;
      }

      .view-detail-btn-text {
        color: #495057;
        text-decoration: underline;
      }

      @include sm-screen {
        .datatable-responsive
          .p-datatable.p-datatable-responsive
          table:not(.mobile-expander-header-style)
          .p-datatable-tbody > tr {
            border-bottom: 2px solid var(--surface-d);

            > td {
              width: calc(100vw - 4.4rem) !important;
            }
          }

        .datatable-responsive
          .p-datatable-tbody td .bt-row {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .bt-cell-value {
              flex-grow: initial;
            }

            .p-button {
              padding: 0.125rem;
            }
          }
      }
    }
  }
}

.view-history-detail-table-container {
  max-width: none;

  .p-card-content {
    padding: 0;
  }

  .bt-header {
    display: none;
  }

  .bt-table {
    min-height: initial !important;

    .p-datatable .p-datatable-tbody > tr {
      background: var(--surface-b) !important;
    }

    .datatable-responsive
      .p-datatable-tbody td .bt-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .bt-cell-value {
          flex-grow: initial;
        }
      }

    @include sm-screen {
      .datatable-responsive
        .p-datatable.p-datatable-responsive
        table:not(.mobile-expander-header-style)
        .p-datatable-tbody > tr {
          border-bottom: 2px solid var(--surface-d);

          > td {
            width: calc(90vw - 4rem) !important;

            .bt-cell-value {
              padding-right: 0.4rem;
            }
          }
        }
    }
  }
}
