.el-content {
  min-height: 50vh;
}
.el-flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .el-wip-text {
    font-size: 4rem;
  }
}
