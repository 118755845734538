@import "../../../../style/utils";

.claim-summary-table,
.claim-summary-row-table {
  .p-card {
    box-shadow: none;
  }
  .p-card-body {
    padding: 0;
  }
  .p-card-content {
    padding: 0;
  }
  .bt-table {
    min-height: 100px;

    .bt-cell-value {
      word-break: break-word;
    }
  }
  .bt-header {
    display: none !important;
  }

  th .p-component {
    margin-top: 0.25rem;
  }

  .p-datatable-thead {
    .selection-column {
      @include sm-screen {
        padding-right: 0.5rem !important;
      }
    }
  }

  .p-calendar input,
  .p-inputnumber input {
    width: 90px;
  }

  .action-column {
    .p-column-title {
      text-align: center;
    }

    .bt-cell {
      text-align: center;
    }
  }

  .record-readonly td {
    color: var(--surface-i);
  }

  .p-datatable .p-datatable-tbody > tr.changed-row {
    background: #fae9ea;
  }

  .p-datatable-thead .bt-claimPeriodFromTo {
    .p-column-title {
      display: none !important;

      @include sm-screen {
        display: -webkit-box !important;
      }
    }
  }
  .bt-claimPeriodFromTo {
    .bt-cell-value {
      display: none;

      @include sm-screen {
        display: block;

        .bt-cell-render {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .summary-row {
    @include sm-screen {
      display: none;
    }
  }

  .attachment-block {
    background: var(--surface-h);
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    padding: 5px 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    cursor: pointer;

    @include sm-screen {
      margin-right: 0;
    }

    .attachment-filename {
      // padding-right: 10px;
    }

    .attachment-delete-btn {
      font-size: 16px;
      margin-left: 7px;
      cursor: pointer;
      display: inline-flex;
      align-items: center;

      &:hover {
        color: #000;
      }
    }

    .attachment-delete-icon {
      font-size: 0.58925rem;
      position: relative;
      top: 1px;
    }
  }

  .p-row-expanded {
    display: none;

    @include sm-screen {
      display: table-row;
      background: var(--surface-a) !important;

      .p-datatable .p-datatable-tbody > tr {
        background: var(--surface-a);
      }
    }

    @media screen and (max-width: 600px) {
      .p-column-title {
        max-width: 200px;
      }
    }
  }

  .p-datatable .p-datatable-tbody > tr {
    background: var(--surface-b);
  }
}

.claim-summary-row-table {
  display: none;

  .bt-claimSummaryRow {
    height: auto !important;
    padding: 0.5rem !important;
  }

  @include sm-screen {
    display: block;
  }
}

.claim-summary-table-print {
  display: none;
}

@include sm-screen {
  .datatable-responsive
    table.mobile-expander-header-style
    > .p-datatable-thead
    > tr
    > th.action-column {
    padding: 0.5rem;
  }
}

@media print {
  .multi-step-form-group:not(:last-child) {
    page-break-after: always;
  }

  .claim-summary-table-display {
    display: none;
  }

  .claim-summary-table-print {
    display: block;
    page-break-before: always;

    .p-datatable-thead > tr > th {
      padding: 2px;
      font-size: 0.6rem;
    }

    .p-datatable-tbody > tr > td {
      padding: 2px;
      font-size: 0.6rem;
    }
  }

  .footer {
    display: none;
  }
}

.claim-summary-table {
  #BaseTable_default_id
    > div
    > div.p-datatable-scrollable-view.p-datatable-unfrozen-view.have-scroll-left
    > div.p-datatable-scrollable-body {
    max-height: 53rem !important;
    overflow: scroll !important;
  }
  #BaseTable_default_id
    > div
    > div.p-datatable-scrollable-view.p-datatable-frozen-view
    > div.p-datatable-scrollable-body {
    max-height: 53rem !important;
  }
  #BaseTable_default_id
    > div
    > div.p-datatable-scrollable-view.p-datatable-unfrozen-view.have-scroll-right
    > div.p-datatable-scrollable-body {
    max-height: 53rem !important;
    overflow: scroll !important;
  }

  @include sm-screen {
    display: initial;
    max-height: 198rem;
    overflow-y: scroll;
  }
}

.base-control.base-control-readonly {
  .claim-summary-table,
  .claim-summary-row-table {
    thead.p-datatable-thead
      > tr
      > .p-selection-column.selection-column
      > span.p-column-title {
      display: none;
    }
    thead.p-datatable-thead
      > tr
      > .p-selection-column.selection-column
      > div.p-checkbox {
      display: none;
    }
    tbody.p-datatable-tbody
      > tr
      > td.p-selection-column.selection-column
      > div.p-checkbox {
      display: none;
    }
  }
}
