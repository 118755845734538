@import "../../../../../style/utils";

.leave-calendar-view {
  min-width: 950px;

  @include sm-screen {
    padding: 0.5rem;
    min-width: 0 !important;

    .header {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .title {
    margin-bottom: 1.5rem;
    @include sm-screen {
      width: 100%;
    }
  }
  .title-desc {
    margin-top: 0.5rem;
  }

  .main {
    background: #fefefe;
    min-height: 500px; // tmp
    padding: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    @include sm-screen {
      flex-wrap: wrap;
      padding: 0.5rem;
      margin-bottom: 60px;
    }
  }

  .main-l {
    width: 50%;
    padding: 1rem;
    @include sm-screen {
      width: 100%;
      padding: 0.5rem;
    }

    .main-l-container {
      max-width: 650px;
      margin: auto;
      position: relative;
    }
  }

  .main-r {
    width: 50%;
    padding: 1rem;
    @include sm-screen {
      width: 100%;
      padding: 0.5rem;
    }
  }

  .legend-list {
    margin-bottom: 3rem;
    color: var(--surface-e);
    @include sm-screen {
      margin-bottom: 1.5rem;
    }

    .custom-legend {
      margin: 0.5rem 0;

      &.statutory-legend {
        font-weight: 500;
        color: var(--table-row-active);
      }
    }
  }

  .section-title {
    font-size: 1rem;
    font-weight: 700;
    margin: 1rem 0rem;
  }

  .calendar-app-status-indicator {
    font-size: 5px;
    height: 5px;
    width: 5px;
    display: flex;
    margin: auto;
  }

  .no-leave-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    opacity: 0.5;
  }

  // control
  .leave-application-meta {
    .base-control {
      label {
        opacity: 0.6;
        margin: 0;
      }

      .p-inputgroup {
        .p-inputtext {
          font-weight: 600;
          padding: 9px 11px;
        }
      }
    }

    .base-control.base-control-readonly {
      .p-inputgroup .p-inputtext {
        border: none;
        padding: 3px;
      }
    }
  }

  .leave-application-detail {
    .base-control {
      label {
        opacity: 0.6;
        margin: 0;
      }

      .p-inputgroup {
        .p-inputtext {
          font-weight: 600;
          padding: 9px 11px;
        }
      }

      .readonly,
      input[readOnly],
      textarea[readOnly],
      .p-dropdown.p-disabled {
        border-top: none;
        border-left: none;
        border-right: none;
        background: var(--surface-c);
        min-height: 38px;
      }
    }

    .leave-application-balance {
      .app-balance-desc {
        opacity: 0.6;

        & > span {
          cursor: pointer;
        }
      }

      .app-balance {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        font-weight: 700;
        color: var(--text-color);
      }
    }
  }

  .application-list {
    color: var(--text-color);

    .application-list-entry,
    .application-list-date {
      padding: 0.5rem 0;
      border-bottom: 1px solid;
      border-color: var(--surface-c);
    }

    .application-list-date {
      color: var(--text-color);
    }

    .application-list-entry {
      & > div:first-child {
        color: var(--text-color);
        font-weight: 700;
      }

      & > div {
        color: var(--text-color-secondary);
        margin: 0.25rem 0px;
      }
    }
  }

  .calendar-spinner {
    position: absolute;
    top: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.3);
    height: calc(100% + 24px);
    width: calc(100% + 24px);
    margin: -14px;

    .main-loading {
      width: 100%;
      height: 100%;
    }
  }
}
