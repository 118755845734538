@import "../../../../style/utils/mixins";
.profile {
  .p-card {
    box-shadow: none;
    padding: 10px;
    .p-card-body {
      .p-card-content {
        padding: 0;
      }
    }
  }
  align-self: flex-start;
  .profile-name {
    font-size: 20px;
    font-weight: bold;
  }
  .profile-staff {
    margin-top: -0.5rem;
  }
  .profile-spacing {
    margin-bottom: 0.5rem;
  }
}

.profile-card {
  margin: 0;
  width: 100%;
  margin-bottom: 1.5rem;
  margin-left: 1px;
  margin-top: 0.1px;
  @include sm-screen {
    margin-bottom: 0.8rem;
    margin-top: -0.7rem;
  }
}

.profile-img {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  object-fit: cover;
  object-position: 0% 0%;
}
.profile-mobile {
  margin-top: -3px;
}
.profile-info {
  width: 100%;
  text-align: start;
}
.profile-email {
  @media screen and (max-width: 1150px) {
    width: 8rem;
  }
  @media screen and (max-width: 991px) {
    width: 16rem;
  }
}
.profile-form {
  align-content: flex-start;
}

.home-profile-container {
  width: calc(100vw - 10rem);
}

.profile-container-content {
  word-break: break-all;
}

.profile-content {
  margin: 0;
}
