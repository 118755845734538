@import "../../../../../style/utils";

.leave-submit-application {
  @include sm-screen {
    padding: 0.5rem;
  }

  .main {
    background: #fefefe;
    min-height: 500px; // tmp
    padding: 1rem;
    @include sm-screen {
      margin-bottom: 60px;
    }
  }

  .leave-application-balance {
    font-size: 0.8rem;
    color: var(--text-color-secondary);
    u {
      cursor: pointer;
    }
    .app-balance {
      color: var(--secondary-color-text);
      font-size: 14px;
      font-weight: 500;
      margin: 5px 0px;
    }
  }

  .bf-main .bf-row .bf-column {
    padding: 0rem 1rem;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .base-control {
    & > div > label {
      margin-bottom: 2px;
      font-size: 0.9rem;
      color: var(--text-color-secondary);
    }

    .p-inputgroup {
      padding-left: 4px;
      font-weight: 500;
    }

    textarea {
      min-height: 35px;
    }

    .p-inputgroup-number .p-inputnumber-button {
      z-index: 1000;
    }
  }

  .footer {
    .p-button {
      @include sm-screen {
        min-width: unset;
      }
    }
  }

  .checkbox-custom {
    display: flex;
    align-items: center;
    margin-top: 2rem !important;

    @include sm-screen {
      align-items: flex-start;
      margin-top: 0 !important;
      flex-direction: row-reverse;
    }
  }
}

.pointer {
  cursor: pointer !important;
  pointer-events: all !important;
}

.leave-submit-legend {
  border-top: 1px solid #dee2e6;
  display: flex;
  align-items: center;
  .hollow-circle {
    width: 2rem;
    height: 2rem;
    border: 2px solid;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin: 1rem 0rem 1rem 1rem;
  }
  .description::before {
    content: "-";
    margin: 0.5rem;
  }
}
