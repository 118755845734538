.menu-group {
  font-weight: bold !important;
  opacity: 1 !important;
  position: relative !important;
  left: -0.25rem !important;
}
.menu-option {
  display: flex;
  align-items: center;
}
.opacity-0 {
  opacity: 0;
}
.toggle-menu-btn {
  padding: 0 !important;
  width: 1rem !important;
  margin-right: 0.5rem !important;
}
.has-tree {
  display: none;
  padding: 0.5rem 1rem;
}
.parentid-root {
  display: flex;
}
.show-option {
  display: flex;
}
.dropdown-tree .p-dropdown-items .p-dropdown-item {
  padding: 0;
}
.dropdown-read-only {
  .p-multiselect-header {
    display: none;
  }
  .p-multiselect-item.p-highlight {
    background: transparent !important;
  }
  .p-multiselect-item {
    pointer-events: none;
  }
}

.multiselect-control-inner {
  .menu-selected-option {
    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .p-multiselect-token {
    padding: 5px;
    background: var(--select-option-primary);
    border-radius: 5px;
    display: inline-flex;
    justify-content: flex-end;
  }
  .p-multiselect-clear-icon {
    color: #6c757d;
    right: 2.357rem;
  }
}

.multiselect-control-no-filter {
  .p-multiselect-filter {
    border: none;
    background: none;
    font-weight: bold;
    pointer-events: none;
    padding: 0;
  }
  .p-multiselect-filter-icon {
    display: none;
  }
}
