@import "../../../../style/utils";

.main-notification {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  .notification-icon {
    background: white;
    color: black !important;
    padding: 0.25rem 0.25rem;

    .p-button-icon {
      font-size: 24px;
    }
  }

  .unread-count {
    position: absolute;
    top: 0;
    right: 0;
    background: var(--primary-color);
    border-radius: 50%;
    font-size: 10px;
    color: white;
    min-width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
  }
}