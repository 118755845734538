@import "../../../../style/utils/index.scss";

.banner-news.sticky {
  position: sticky;
  position: -webkit-sticky;
  z-index: 1;
  top: 0;
}

.banner-news {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  height: 8rem;

  .p-inline-message {
    align-items: flex-start;
    justify-content: start;
  }
  .p-message {
    margin: 0rem;
  }
  .p-message:not(:last-child) {
    margin-bottom: 1rem;
  }

  .p-button.p-button-icon-only {
    color: #ff5757;
  }
  .main-header {
    height: 82px;
    background: white;
    display: contents;
    .p-menubar {
      background: white;
      align-self: start;
      .p-menubar-start {
        margin-right: 1rem;
      }
    }
    /* make it fixed when small screen */
    @include sm-screen {
      position: relative;
      display: block;
      top: 0;
      width: 100%;
      z-index: 999;
    }
    .main-header-right-content {
      flex-wrap: nowrap;
    }
  }
}
