@import "../../style/utils";
.main-content {
  margin-left: 6rem; //margin in relative to sidebar
  padding: 1.6rem 4rem 1.6rem 1.8rem;
  // height: calc(100vh - 82px);
  overflow: auto; //avoid children scrolling
  width: 100%;
  @include sm-screen {
    // padding-top: calc(82px + 0.5rem);
    padding-bottom: 6rem;
    /* */
    height: unset;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin: 0rem;
  }

  @media screen and (min-width: 769px) {
    .home-left-panel {
      padding: 0 14px 10px 14px;
    }
    .home-right-panel {
      padding: 0 14px 10px 14px;
    }
  }
}

.content-web {
  padding-top: 14rem;
  display: flex;
  flex: 1 1 auto;
  .hris-sidebar {
    flex-flow: column;
    position: fixed;
    overflow-y: auto;

    .p-sidebar-content {
      height: 100%;
      background-color: var(--surface-a);
    }

    &.web {
      z-index: 0;
      .sidebar-left {
        top: 14rem;
      }
    }

    &.mobile {
      z-index: 2000;
      top: 0;
      bottom: 0;
      top: 0;
    }

    .sidebar-left {
      .p-sidebar-content {
        display: flex;
        .sidebar-left-scroll-panel {
          overflow-y: auto;
          > div.p-scrollpanel-wrapper {
            background-color: var(--sidebar-primary-color);
          }
        }
        .menu-item-my-docs-submenus-container {
          &.selected-menu-item-my-docs-submenus-container {
            overflow-y: auto;
            background-color: var(--surface-a);
          }
        }
      }
    }
  }
}

.no-banner {
  .content-web {
    padding-top: 6rem;
    .hris-sidebar {
      .sidebar-left {
        top: 6rem;
      }
    }
  }

  .main-header {
    top: 0rem;
  }

  .banner-news {
    height: 0rem;
  }
}

.mobile-footer {
  @include sm-screen {
    .footer {
      bottom: 82px !important;
    }
  }
}

.push-margin {
  .main-content {
    margin-left: 22rem;
    @include sm-screen {
      margin: 0;
    }
  }
}

.banner-news {
  z-index: 2;
}

.main-header {
  z-index: 2;
}
