@import "../../../../../style/utils";

.el-content {
  min-height: 50vh;
}
.el-flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .el-wip-text {
    font-size: 4rem;
  }
}

.leave-submit-application {
  .leave-application-meta {
    .leave-workflow-btn {
      margin-left: 5px;
      font-weight: 500;
      color: var(--primary-color);
      cursor: pointer;
    }
  }

  .leave-workflow {
    .leave-workflow-title {
      margin-bottom: 1rem;
      font-size: 16px;
      font-weight: 500;
      @include sm-screen {
        width: 100%;
      }
    }

    .leave-workflow-tabmenu {
      margin-bottom: 0.5rem;

      @include sm-screen {
        width: 100%;

        li > a.p-menuitem-link {
          padding: 0.6rem;
        }
      }
    }

    .leave-workflow-header-table {
      .p-datatable-thead > tr > th,
      .p-datatable-tbody > tr > td {
        padding: 0.5rem;
      }

      .workflow-expander-end {
        max-width: 0;
        padding: 0 !important;

        button {
          display: none;
        }
      }

      @include sm-screen {
        .p-datatable-thead {
          display: none;
        }

        .p-datatable-tbody > tr {
          display: flex;
          flex-wrap: wrap;
          border-bottom: 1px solid #e9ecef;

          > td {
            width: 100% !important;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            border: none;

            .p-column-title {
              display: block;
            }
            .p-column-value {
              font-weight: 500;
            }
          }
        }

        .p-datatable-tbody .p-row-expanded > td {
          flex-wrap: wrap;
        }

        .workflow-expander-start {
          display: none !important;
        }
        .workflow-expander-end {
          padding: 0.2rem !important;
          max-width: none;

          button {
            display: inline-flex;
          }
        }
      }
    }

    .leave-workflow-detail-table,
    .leave-workflow-content-table {
      .p-datatable-thead > tr > th .p-column-title {
        display: block;
      }
    }
  }

  .checkbox-custom {
    display: flex;
    align-items: center;
    margin-top: 2rem !important;

    @include sm-screen {
      align-items: flex-start;
      margin-top: 0 !important;
      flex-direction: row-reverse;
    }
  }
}
