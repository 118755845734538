@import "./../../../../../../style/utils/index.scss";

.login-form-skeleton {
  background-color: var(--skeleton-bg);
  margin: 1rem;
  width: 100%;
  height: 2vh;
}
.login-form-entity-display-name {
  width: 100%;
  font-size: 18px;
  text-align: center;
}
.login-form-tab {
  // .p-tabview-nav {
  //   overflow-x: scroll;
  //   flex-wrap: nowrap;
  //   overflow-y: hidden;
  //   height: 3.6rem;
  // }
  .login-tabview {
    .p-tabview-nav {
      flex-wrap: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
      padding-bottom: 0.3rem;
      border: none;

      li {
        flex: 1;
        justify-content: center;
      }

      .p-tabview-nav-link {
        height: 100%;
        justify-content: center;
        padding: 1rem 0 1rem 0;
        // border-bottom-width: thin;//comment
      }
    }
  }

  .ep-login-container {
    .authentication-button {
      width: 100%;
      min-height: 3.5rem;
    }

    .retry-message-button {
      background: none !important;
      border: none;
      padding: 0 !important;
      color: var(--primary-color);
      text-decoration: none;

      &:hover {
        color: var(--primary-color) !important;
      }
    }

    .authentication-logout-button {
      width: 100%;
      min-height: 3.5rem;

      .p-button-icon {
        display: none;
      }
    }
  }
}
