@import "../../../../style/utils/";

.multi-step-form {
  &-group {
    background: #fff;
    margin: 2rem 1.5rem;
    margin-top: 0;
    padding: 28px;

    .title {
      font-size: 1.2rem;
      font-weight: 500;
    }

    &.horizontal {
      .bf-row {
        display: block;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }

      .bf-column {
        width: 100%;
      }

      .p-field {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 0;

        & > label {
          width: 300px;

          &::after {
            content: ":";
          }
        }

        .p-inputgroup {
          flex: 1;
          min-height: 27px;
          font-size: 15px;
        }

        .p-component {
          display: flex;
          align-items: center;
        }

        .control-hint-right {
          flex: 1;
        }
        .control-hint-bottom {
          margin-left: 300px;
          flex-basis: 100%;
        }
      }
    }
  }

  // panel
  .multi-step-form-panel {
    .panel-header {
      padding: 0;
      border: none;
      background: #fff;
      display: flex;
      justify-content: space-between;

      .multi-step-form-show-more-btn {
        background: transparent;
        color: var(--primary-color);
        min-width: 122px;
        height: 35px;
      }
    }

    .panel-content {
      padding: 0;
      border: none;
      max-height: 200rem;
      overflow: hidden;
      // transition: max-height 0.2s ease-in-out;

      &.panel-content-collapsed {
        max-height: 0;
      }
    }
  }

  // control
  .bf-main {
    .custom-holder {
      &.p-lg-1,
      &.p-lg-2,
      &.p-lg-3,
      &.p-lg-4,
      &.p-lg-5,
      &.p-lg-6,
      &.p-lg-7,
      &.p-lg-8,
      &.p-lg-9,
      &.p-lg-10,
      &.p-lg-11 {
        padding-right: 0.5rem;
      }
    }

    .bf-control {
      padding: 0;
    }

    .base-control {
      & > div > label {
        margin: 0;
        color: #515151;
        font-size: 13px;
        white-space: normal;
      }

      // Error handling
      // red border
      // .p-inputgroup.p-inputgroup-error > div,
      // .p-inputgroup.p-inputgroup-error > input,
      // .p-inputgroup.p-inputgroup-error > span > input {
      //   border: 0.2rem solid red;
      // }
      .p-inputgroup {
        align-items: center;
        min-height: 27px;
      }

      // Error
      .p-component.p-invalid {
        border: 0.2rem solid red;

        & .p-inputtext {
          border: none;
        }
      }

      // hide error text
      small.p-invalid-custom {
        display: none !important;
      }

      .p-field > label {
        margin-bottom: 0.25rem;
      }
      .p-field > label::before {
        content: none;
      }
      .upload-control {
        & > label {
          font-weight: 700;
          font-size: 16px;
        }
      }

      .checkbox-control {
        .p-inputgroup {
          align-items: flex-start;
        }

        .p-checkbox-label {
          white-space: normal;

          &::before {
            content: "";
          }
        }

        .p-checkbox-box.p-highlight {
          border-color: #707070;

          .p-checkbox-icon {
            width: 100%;
            height: 100%;
            border: 3px #fff solid;
            background-color: var(--primary-color);
          }

          .pi-check:before {
            content: "";
          }
        }

        // Error
        .p-inputgroup-error > .p-checkbox-label {
          color: red;
        }

        &.p-invalid {
          border: none;
        }
      }

      .label-control {
        margin: -0.25rem 0 0.25rem 0;
      }
    }
  }

  // read only
  .base-control.base-control-readonly {
    .p-component {
      border: none;
    }

    & > div > label {
      margin: 0 !important;
      color: #515151 !important;
      font-size: 13px;
    }

    & > div > label::before {
      content: none;
    }

    .p-checkbox-label {
      white-space: normal;
      overflow: inherit;
      text-overflow: inherit;
      display: inherit;
      // margin-left: 0.5rem;
      flex: 1;
    }

    .label-control {
      padding: 0;
      color: black;
      font-size: 15px;
      display: block;
      margin: 0.25rem 0 0.25rem 0;
    }

    .p-inputtext {
      padding: 0;
      color: black;
      font-size: 15px;
    }

    // control
    & > div.upload-control > label {
      font-size: 16px;
      margin-bottom: 0.5rem;
    }

    .message-input-block {
      .p-inputtext {
        padding: 1rem;
        padding-right: 155px;
      }
    }

    .p-invalid-custom {
      display: none !important;
    }
    .p-disabled,
    .p-component:disabled {
      opacity: 1 !important;
    }
    .p-inputnumber-button-group {
      display: none !important;
    }
    .p-dropdown .p-dropdown-trigger {
      display: none !important;
    }
    .p-inputgroup-addon:last-child,
    .p-inputgroup button:last-child,
    .p-inputgroup input:last-child,
    .p-inputgroup > .p-inputwrapper:last-child,
    .p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
      background: transparent !important;
      border-top: 0 !important;
      border-right: 0 !important;
      border-left: 0 !important;
      &.custom-input-action-btn {
        background: var(--primary-color) !important;
      }
    }
    .p-inputgroup-addon:first-child,
    .p-inputgroup button:first-child,
    .p-inputgroup input:first-child,
    .p-inputgroup > .p-inputwrapper:first-child,
    .p-inputgroup > .p-inputwrapper:first-child > .p-inputtext {
      background: transparent !important;
      border-top: 0 !important;
      border-right: 0 !important;
      border-left: 0 !important;

      &.custom-input-action-btn {
        background: var(--primary-color) !important;
      }
    }
    .p-inputgroup > .p-component,
    .p-inputgroup > .p-inputwrapper > .p-inputtext,
    .p-inputgroup > .p-float-label > .p-component {
      background: transparent !important;
      border-top: 0 !important;
      border-right: 0 !important;
      border-left: 0 !important;
    }
    .silder-number {
      display: none !important;
    }
    .p-inputtextarea {
      overflow: hidden !important;
    }
    .p-inputgroup button.p-button:not(.custom-input-action-btn) {
      display: none !important;
    }
  }

  @include sm-screen {
    &-group {
      margin: 20px 0px;
      padding: 14px;

      .title {
        font-weight: 500;
      }

      &.horizontal {
        .p-field {
          & > label {
            width: 100%;
          }
          .control-hint-bottom {
            margin-left: 200px;
          }

          .control-hint-bottom {
            margin: 0;
            flex-basis: 100%;
          }
        }
      }
    }
  }
}
