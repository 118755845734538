@import "./../../../../../src/style/utils/index.scss";

.filter-dropdown {
  margin-bottom: 1rem;
  margin-right: 1rem;
  @include sm-screen {
    width: 100%;
  }
}

.filter-dropdown-none {
  display: none;
}

@include lg-screen {
  #file-download-table
    > div.p-datatable-scrollable-wrapper
    > div
    > div.p-datatable-scrollable-header
    > div
    > table
    > thead
    > tr
    > th.p-sortable-column.bt-fileName
    > span.p-column-title {
    display: inline;
  }
}

#file-download-table
  > div.p-datatable-scrollable-wrapper
  > div
  > div.p-datatable-scrollable-header
  > div
  > table
  > thead
  > tr
  > th.p-sortable-disabled.p-selection-column.selection-column {
  width: 1em !important;
}

#file-download-table
  > div.p-datatable-scrollable-wrapper
  > div
  > div.p-datatable-scrollable-body
  > table
  > tbody
  > tr
  > td.bt-frozen-right.action-column
  > div {
  display: flex;
  justify-content: flex-end;
}

//hide expand all and collapse all button
#document-download-table > div.p-datatable-header {
  display: none;
}

//hide check-all checkbox for files
#file-download-table
  > div.p-datatable-scrollable-wrapper
  > div
  > div.p-datatable-scrollable-header
  > div
  > table
  > thead
  > tr
  > th.p-sortable-disabled.p-selection-column.selection-column
  > div {
  display: none;
}

//allow scrollable
@include sm-screen {
  #file-download-table > div.p-datatable-wrapper {
    overflow-y: scroll;
    max-height: 360px;
  }
}

@include sm-screen {
  #file-download-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td.bt-frozen-right.action-column
    > div {
    justify-content: flex-end;
  }
}

@include sm-screen {
  #file-download-table
    > div.p-datatable-wrapper
    > table
    > tbody
    > tr
    > td.bt-fileName.table-expandable-header
    > div
    > span {
    display: none;
  }
}

#file-download-table
  > div.p-datatable-wrapper
  > table
  > thead
  > tr
  > th.p-sortable-disabled.p-selection-column.selection-column
  > div {
  display: none;
}

#file-download-table
  > div.p-datatable-scrollable-wrapper
  > div
  > div.p-datatable-scrollable-body
  > table
  > tbody
  > tr
  > td.selection-column.p-selection-column {
  width: 1em !important;
}

#file-download-table
  > div.p-datatable-scrollable-wrapper
  > div
  > div.p-datatable-scrollable-header
  > div
  > table
  > thead
  > tr
  > th.p-sortable-disabled.p-selection-column.selection-column {
  width: 1em !important;
}

#bt-table-document-download-table
  > div.bt-header
  > div.animated.slideInUp
  > div {
  margin-top: 1rem;
  min-height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

#document-download-table
  > div.p-datatable-scrollable-wrapper
  > div
  > div.p-datatable-scrollable-body
  > table
  > tbody
  > tr
  > td.bt-frozen-right.action-column
  > div {
  display: flex;
  justify-content: flex-end;
}

// #BaseTable_default_id
//   > div.p-datatable-scrollable-wrapper
//   > div
//   > div.p-datatable-scrollable-header
//   > div
//   > table
//   > thead
//   > tr
//   span.p-column-title {
//   display: inline;
// }

.hr-corner-bold {
  font-weight: bold;
  span {
    font-weight: bold;
  }
}

.document-download-table-hris-bt-expander-column {
  width: 1em;
  padding: 0;
}

//#region enable display of filter in child table
#bt-table-file-download-table > div.bt-header {
  display: inline;
}

#bt-table-file-download-table > div.bt-header > div.animated.slideInUp > div {
  margin-top: 0rem;
  min-height: auto;
}
//#endregion

#document-download-table > div.p-datatable-wrapper > table {
  table-layout: auto;
}

#document-download-table
  > div.p-datatable-wrapper
  > table
  > tbody
  > tr
  > td.bt-description.table-expandable-header
  > div
  > div
  > table
  > tbody
  > tr
  > td
  > button {
  width: auto;
  height: auto;
  margin-right: 1rem;
}

#document-download-table
  > div.p-datatable-wrapper
  > table
  > tbody
  > tr
  > td.hris-bt-expander-column {
  display: none;
}
#document-download-table
  > div.p-datatable-wrapper
  > table
  > thead
  > tr
  > th.p-sortable-disabled.hris-bt-expander-column {
  display: none;
}

#document-download-table
  > div.p-datatable-wrapper
  > table
  > thead
  > tr
  > th.p-sortable-disabled.bt-frozen-right.action-column {
  width: 1rem !important;
}

#document-download-table
  > div.p-datatable-wrapper
  > table
  > tbody
  > tr
  > td.bt-frozen-right.action-column {
  width: 1rem !important;
  .bt-cell {
    display: flex;
    justify-content: flex-end;
  }
}
#document-download-table > div.p-datatable-wrapper > table > tbody {
  .p-row-expanded > td > div.bt-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    .bt-cell-value {
      @include sm-screen {
        margin-left: 1rem;
        text-align: right;
      }
      display: flex;
      justify-content: flex-end;
    }
  }
}

#document-download-table
  > div.p-datatable-wrapper
  > table
  > tbody
  > tr
  > td.bt-description.table-expandable-header
  > div
  > div
  > table {
  table-layout: auto;
}

//auto width for select folder dropdown in lg-screen only
@include lg-screen {
  #bt-table-document-download-table
    > div.bt-header
    > div.animated.slideInUp
    > div
    > div.p-dropdown.p-component.p-inputwrapper.filter-dropdown.p-dropdown-clearable.p-inputwrapper-filled {
    width: auto;
    max-width: 500px;
  }
}
