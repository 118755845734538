/* Notification List */
.n-m-btn-refresh {
  margin: 0rem 0.5rem 0rem 0rem;
}

.n-m-title {
  margin: 1rem 0rem 0rem 0.5rem;
}

.n-m-scroller {
  margin: 1rem 0.5rem 0rem 0.5rem;
}

.m-n-t-s-card-container {
  padding: 1rem 0.5rem 0rem 0.5rem;
}

.m-n-t-s-card-container-content {
  margin: 0.2rem 0.1rem 0rem 0.1rem;
}

.m-n-t-card-container {
  padding: 1.5rem 0.5rem 1.5rem 0.5rem;
}

.m-n-t-card-container:active {
  background: var(--data-scoller-active);
}

.data-scroller-container {
  & > .p-datascroller.p-component.n-m-scroller {
    & > .p-datascroller-content {
      & > ul.p-datascroller-list {
        & > li:not(:last-child) {
          border-bottom: 0.15rem solid var(--data-scroller-separator);
        }
      }
    }
  }
}

.data-scroller-container {
  & > .p-datascroller.p-component.n-m-scroller {
    & > .p-datascroller-content {
      & > ul.p-datascroller-list {
        & > li:last-child {
          border-bottom: 0.15rem solid var(--surface-a);
        }
      }
    }
  }
}

.data-scroller-no-data {
  margin: 1rem 0rem 0rem 0.5rem;
}

.n-m-t-content-container {
  margin: 1rem 0rem 0rem 0rem;
}

.n-m-t-title {
  font-family: var(--font-family);
}

.n-m-t-sender,
.n-m-t-date-time {
  margin-left: 0.5rem;
  color: var(--surface-d);
  font-family: var(--font-family);
}

.n-m-t-unread-n {
  font-weight: bold;
}

/* Notification List Details */
.n-m-d-container {
  background-color: var(--surface-a);
}

.t-r-c-left {
  margin-right: 1rem;
}

.t-r-c-right {
  margin-right: 0rem;
}

.n-m-d-grid-container {
  margin: 1rem 0.5rem 0rem 0.5rem;
  font-family: var(--font-family);
}

.n-m-d-sender,
.n-m-d-date-time {
  color: var(--text-color-secondary);
}

.n-m-d-title {
  margin-top: 1rem;
  font-weight: bold;
}

.n-m-d-content {
  margin-top: 1rem;
}

/* Temporary Button Margin Top Spaces */
.n-m-d-take-action-btn {
  margin: 5rem 0rem 2rem 0rem;
  width: calc(90vw);
  border-radius: 3px;
}

.n-m-container
  > .data-scroller-container
  > .p-datascroller.p-component.n-m-scroller.p-datascroller-inline
  > .p-datascroller-content
  > .p-datascroller-list {
  height: calc(100vh - 17rem);
}
