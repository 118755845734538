.hris-mfa-resendOtp-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*input has OS specific font-family*/
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
  :hover {
    color: var(--primary-color);
  }
}

.hris-mfa-resend-timer {
  padding: 0;
  margin: 0;
}
