.application-type-control {
  table {
    td {
      padding: 0.5rem 0.15rem;
    }
    tr > td:nth-child(2) {
      min-width: 200px;
    }
  }

  .p-dropdown {
    width: 100%;
    min-width: 250px;
  }

  label {
    display: block !important;
  }
}
