.hris-multi-entites-logo-container {
  .hris-multi-entites-logo-size {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    object-position: 0% 0%;
  }
  display: inline-block;
  padding-left: 0px !important;
  padding-right: 20px !important;
}

.main-entity-info {
  display: inline-block;
  vertical-align: center;
}

.hris-multi-entity-flex {
  display: flex;
  align-items: center;
}
