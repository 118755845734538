.notification-item-modal {
  display: flex;
  div {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    .notification-publishDate {
      justify-content: flex-end;
    }
  }
}

/* Notification Table - Action Button */
.p-button.p-button-text.action-button,
.p-button.p-button-text.action-button:hover,
.p-button.p-button-text.action-button:active {
  color: var(--surface-f);
}

#notification-table .p-datatable-tbody > .unread-notification {
  font-weight: bold;
}

#bt-table-notification-table .bt-action-head button:last-child {
  display: none;
}
