@import "../../../../style/utils/mixins";

.footer-copyright {
  position: static;
  text-align: center;
  margin-top: 1rem;
  // @media (max-width: 1300px) {
  //   margin-left: 16rem;
  // }
  @include sm-screen {
    display: none;
  }
}
.footer-terms {
  position: static;
  margin-bottom: 1rem;
  text-align: center;

  @include sm-screen {
    display: none;
  }
}
.footer-font {
  color: black;
}

// for bottom nav bar
.l-footer {
  display: none;
  z-index: 1;
  overflow-x: scroll;
  @include sm-screen {
    display: flex;
  }

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  flex-wrap: nowrap;

  .l-footer-item {
    cursor: pointer;
    flex-grow: 1;
    background-color: var(--surface-f);
    border-bottom: 5px solid var(--surface-f);

    &.active {
      background-color: var(--surface-a);
      border-bottom: 5px solid var(--sidebar-active-menu);
      .l-footer-item-content {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        margin: auto;
        align-items: center;
        .l-footer-item-icon {
          height: 22px;
          width: 22px;
          color: var(--sidebar-active-menu);

          /* https://codepen.io/sosuke/pen/Pjoqqp */

          /* As noted in the CodePen, if your SVG isn't black (ours was white), adding brightness(0) saturate(100%) to the beginning of the list of filters will first turn it 100% black, which enables the other filters to change it to the correct color. */
          filter: brightness(0) saturate(100%) invert(14%) sepia(61%)
            saturate(6173%) hue-rotate(350deg) brightness(81%) contrast(90%);
        }
        .l-footer-item-text {
          color: var(--sidebar-active-menu);
        }
      }
    }
    &:hover active {
      background: var(--primary-color);
    }
    .l-footer-item-content {
      text-decoration: none;
      display: flex;
      flex-direction: column;
      margin: auto;
      align-items: center;
      .l-footer-item-icon {
        color: var(--surface-a);
        height: 22px;
        width: 22px;
      }
      .l-footer-item-text {
        color: var(--primary-color-text);
      }
      .l-footer-item-text:active {
        color: var(--sidebar-active-menu);
      }
    }
  }
}
