.payslip-table {
  > .p-card.p-component
    > .p-card-body
    > .p-card-content
    > .bt-table.bt-table-custom
    > .datatable-responsive.expander-datatable
    > #payslip-table
    > .p-datatable-wrapper
    > .mobile-expander-header-style
    > .p-datatable-thead
    > tr {
    > .bt-frozen-right.action-column.table-expandable-header {
      padding: 0;
      width: 3rem;
    }
    > th {
      padding: 0.5rem 1.5rem 0.5rem 0.5rem;
      > .p-column-title {
        display: inline;
      }
      > .p-sortable-column-icon.pi.pi-fw {
        display: inline;
        position: absolute;
        right: 0;
        top: calc(50% - 0.4rem);
        font-size: 12px;
        margin-right: 0.5rem;
      }
    }
  }
}
