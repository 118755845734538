.error-404-bg {
  background-color: var(--surface-a);
  color: var(--error-highlight);
  font-family: IBM Plex Sans;
  font-weight: bold;

  margin: 0.5rem;
  margin-top: 1.5rem;
  padding: 0.5rem;
  height: 500px;

  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.error-404-title {
  font-size: 72px;
  display: block;
}

.error-404-subtitle {
  font-size: 36px;
  display: block;
}

.error-404-text {
  margin-top: 2rem;
  color: var(--surface-f);
  font-size: 18px;
  display: block;
}

.error-404-text-message {
  color: var(--primary-color);
  cursor: pointer;
}
