.shortcut-mobile {
  div {
    background-color: var(--surface-a);
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    column-gap: normal;
    height: 100%;
  }
  a {
    text-decoration: none;
    color: var(--primary-color);
    width: 100%;
    span {
      display: flex;
      flex-direction: column;
      margin: auto;
      align-items: center;
      height: 5rem;
      width: 5rem;
      font-size: 4rem;
      filter: brightness(0) saturate(100%) invert(14%) sepia(61%)
        saturate(6173%) hue-rotate(350deg) brightness(81%) contrast(90%);
    }
    p {
      color: var(--surface-f);
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      margin: 0rem;
      padding: 0rem;
    }
  }
}

.shortcut {
  div {
    background-color: var(--surface-a);
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;
    column-gap: normal;
  }
  a {
    text-decoration: none;
    color: var(--primary-color);
    width: 100%;
    span {
      display: flex;
      flex-direction: column;
      margin: auto;
      align-items: center;
      height: 5rem;
      width: 5rem;
      font-size: 4rem;
      filter: brightness(0) saturate(100%) invert(14%) sepia(61%)
        saturate(6173%) hue-rotate(350deg) brightness(81%) contrast(90%);
    }
    p {
      color: var(--surface-f);
      display: flex;
      flex-wrap: wrap;
      text-align: center;
    }
  }
}

.shortcut-grid {
  margin-bottom: 0.5rem;
}

.shortcut-grid-mobile {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
