@import "../../../../../style/utils";

.leave-report {
  @include sm-screen {
    padding: 0.5rem;
    margin-bottom: 60px;
  }

  .section-container {
    background: white;
    min-height: 100px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 2rem;
  }

  .filter-container {
    .p-multiselect-label {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
    }

    .p-multiselect-token {
      padding: 5px;
      background: var(--select-option-primary);
      border-radius: 5px;
      min-width: 87px;
      display: flex;
      justify-content: flex-end;
      .p-multiselect-token-icon {
        margin: 4px 0px 3px 3px;
      }
    }
  }

  .report-tab {
    @include sm-screen {
      .p-dropdown {
        width: 100%;
      }
    }
  }

  .report-container {
    .report-title {
      font-size: 1.25rem;
      color: var(--text-color);
      margin-bottom: 1rem;
    }

    & > .p-card > .p-card-body {
      padding: 0;
    }

    & > .p-card > .p-card-body > .p-card-content {
      padding: 0;
    }
  }

  .bt-table.bt-table-custom {
    min-height: 100px !important;
  }

  .bt-table .bt-header {
    //display: none;
    .bt-header-toolbar {
      display: none;
    }

    .bt-toolbar {
      background: var(--surface-b);
      margin-top: 0;
    }
  }

  .bt-table tbody td {
    background: var(--surface-b);
    border-color: var(--surface-b);
  }

  .bt-table .p-datatable-scrollable-body {
    max-height: 55vh;
    overflow-y: scroll;
  }

  .p-paginator {
    background: var(--surface-b);
    border-color: var(--surface-b);
  }

  .footer {
    .btn-mobile-text {
      display: none;
    }
  }

  @include sm-screen {
    .footer {
      padding: 0 1rem;

      .p-button {
        padding: 0.5rem 1.2rem;
      }

      .btn-desktop-text {
        display: none;
      }

      .btn-mobile-text {
        display: inline;
      }
    }
  }
}
