@import "../../../../../style/utils";
.nwow-planner {
  @include sm-screen {
    padding: 0.5rem;
  }

  .title {
    margin-bottom: 1.5rem;
  }

  .title-desc {
    margin-top: 0.5rem;
  }

  .nwow-planner-filter {
    .filter-form {
      margin: 0 0 1.5rem 0;
      padding: 28px;
      background: #fff;
    }

    .filter-title {
      display: flex;
      justify-content: space-between;
    }

    .show-more-btn {
      background: transparent;
      color: var(--primary-color);
      min-width: 122px;
      height: 35px;
    }

    .filter-form-container {
      max-height: 40rem;
      overflow: hidden;

      &.filter-form-container-collapsed {
        max-height: 0;
      }
    }

    .p-multiselect-label {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
    }

    .p-multiselect-token {
      padding: 0 5px;
      background: var(--select-option-primary);
      border-radius: 5px;
      min-width: 87px;
      height: 19px;
      display: flex;
      justify-content: flex-end;
      .p-multiselect-token-icon {
        margin: 4px 0px 3px 3px;
      }
    }

    .filter-form-action {
      display: flex;
      justify-content: flex-end;
    }
  }

  .nwow-planner-container {
    background: #fff;
    padding: 2rem;
    @include sm-screen {
      padding: 0.5rem;
      margin-bottom: 60px;
    }
  }

  .nwow-planner-legend {
    padding: 1rem;

    .legend-title {
      font-weight: bold;
    }

    .legend-container {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;

      .custom-legend {
        flex: 0 1 calc(100% / 6);
        @media screen and (max-width: 992px) {
          flex: 0 1 100%;
        }

        &.approved-nwow {
          .legend-icon {
            border: 2px solid #495057;
          }

          .legend-text {
            color: #495057;
          }
        }
      }
    }
  }

  .nwow-planner-table {
    .p-datatable-thead {
      th.info-cell,
      th.nwow-cell {
        background: var(--surface-d);
      }
    }

    .p-datatable-tbody {
      tr.p-rowgroup-header > td {
        padding: 0.3rem;
        border-width: 2px;
        background: #c9c9c9;

        .row-group-header {
          margin-left: 10px;
          font-weight: bold;
        }
      }

      td.info-cell,
      td.nwow-cell {
        background: var(--surface-b);
      }
    }

    .info-cell,
    .nwow-cell {
      text-align: center;
      border-width: 2px;
      height: 40px;
    }

    .info-cell {
      padding: 0.5rem !important;
    }

    .info-cell__employee {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .nwow-cell {
      padding: 0 !important;
      // height: 1em;
    }

    .p-datatable-frozen-view {
      @include sm-screen {
        width: 15rem !important;

        .info-cell__year {
          display: none;
        }
      }
    }

    .p-datatable-unfrozen-view {
      .p-datatable-scrollable-body {
        padding-bottom: 1px;

        .p-rowgroup-header > td {
          height: 38px;

          button,
          .p-rowgroup-header-name {
            display: none;
          }
        }
      }

      @include sm-screen {
        width: calc(100% - 15rem) !important;
        left: 15rem !important;
      }
    }
  }
}
.ppi::before {
  font-weight: 700;
}

.ppi::after {
  font-weight: 700;
}
