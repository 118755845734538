.custom-calendar {
  width: 100%;

  .p-datepicker {
    border: none;
    color: black;
  }

  .p-datepicker .p-datepicker-header {
    padding: 0.5rem;
    color: #495057;
    background: #ffffff;
    font-weight: 600;
    margin: 0;
    border: none;
  }

  .p-datepicker table td > span {
    width: 3rem;
    height: 2.5rem;
    border-radius: 0.5rem;
    transition: box-shadow 0.2s;
    border: 1px solid transparent;
    font-weight: 500;

    &:not(.p-highlight):hover {
      background: #4d4d4d !important;
      color: white;
    }
  }

  .p-datepicker table td > span.p-highlight {
    color: white;
    font-weight: 500;
    background: black;
  }

  .p-datepicker table td.p-datepicker-today > span:not(.p-highlight) {
    background: var(--table-row-active);
    color: white;
  }

  .p-datepicker
    table
    td.p-datepicker-today
    > span:not(.p-highlight)
    > div
    > span {
    color: white;
  }

  .p-datepicker-other-month {
    opacity: 0.3;
  }

  .date-marker {
    font-size: 5px;
    height: 5px;
    width: 5px;
    display: flex;
    margin: auto;
  }

  .p-disabled {
    opacity: 1;
  }

  .custom-date-template {
    & > span:hover {
      background: inherit !important;
    }

    .custom-date-disabled {
      opacity: 0.3;
    }

    .custom-date-red-day {
      color: var(--table-row-active);
      font-weight: 900;
      opacity: 1;
    }

    .custom-date-marked {
      font-weight: 900;
    }
  }
}
