@import "../../../../../style/utils";

.leave-applicaiton-list {
  @include sm-screen {
    padding: 0.5rem;
  }

  .header {
    flex-wrap: wrap;

    @include sm-screen {
      .p-dropdown {
        display: none;
      }
    }
  }

  .title {
    margin-bottom: 1.5rem;

    @include sm-screen {
      width: 100%;
      order: 2;
    }
  }

  .title-desc {
    margin-top: 0.5rem;
  }

  .leave-menu {
    @include sm-screen {
      order: 1;
    }
  }

  .main {
    background: #fefefe;
    min-height: 500px; // tmp
  }

  .nav-dropdown {
    max-height: 40px;
    min-width: 200px;
    .p-dropdown-label.p-placeholder {
      display: inline-flex;
      align-items: center;
      padding: 7px 15px;
    }
  }

  .leave-application-list-datatable {
    .p-card .p-card-content {
      padding: 0;
    }

    /*@include sm-screen {*/
      .bt-table {
        min-height: 0 !important;
      }
    /*}*/

    .bt-table .bt-header {
      .bt-header-toolbar {
        display: none;
      }

      .bt-toolbar {
        background: var(--surface-b);
        margin-top: 0;
        flex-wrap: wrap;

        .bt-toolbar-content {
          padding: 0.5rem 1rem;
          display: flex;
          align-items: center;

          @include sm-screen {
            width: 100%;
          }
        }

        .filter-year-container {
          padding-left: 10px;
        }

        @include sm-screen {
          .bt-action.bt-action-search {
            padding: 0.5rem;
          }
        }

        .bt-calendarbtn {
          background: transparent;
          border-color: transparent;

          &:hover {
            background: #e9ecef;
            border-color: #ced4da;
            color: #495057;
          }

          .p-button-icon {
            color: var(--text-color-secondary);
          }
        }

        .bt-action > .p-button {
          width: 40px;
        }
      }

      .animated {
        animation-delay: unset !important;
        animation-duration: unset !important;
      }
    }

    .bt-table .bt-cell-value {
      word-break: break-word;
    }

    .bt-table .p-datatable-scrollable-wrapper {
      max-height: 55vh;
      overflow-y: auto;
    }

    .p-datatable-unfrozen-view {
      left: 5rem !important;
      width: calc(100% - 5rem) !important;

      &:before {
        width: 0;
      }
    }

    .p-datatable .p-datatable-tbody > tr {
      background: var(--surface-b);
    }

    .p-datatable .p-datatable-tbody .action-column {
      text-align: center;
    }

    .p-datatable .p-datatable-thead > tr,
    .p-datatable .p-datatable-tbody > tr {
      .bt-leaveFromTo {
        .p-column-title,
        .bt-cell-value {
          display: none;
        }
      }
    }

    #BaseTable_default_id {
      .bt-leaveFromTo .p-column-title {
        display: none;
      }
    }

    .p-datatable .p-datatable-tbody {
      .p-row-expanded {
        display: none;
      }
    }

    @include sm-screen {
      .p-datatable-thead > tr > th.bt-leaveType,
      .p-datatable-tbody > tr > td.bt-leaveType {
        width: auto !important;
      }
      .p-datatable-thead > tr > th.bt-leaveFromTo,
      .p-datatable-tbody > tr > td.bt-leaveFromTo {
        width: auto !important;
      }

      .p-datatable-thead > tr > th.bt-leaveFromTo {
        .p-column-title {
          display: inline !important;
        }
      }

      .p-datatable-tbody > tr > td.action-column {
        .p-column-title {
          display: none !important;
        }
      }

      .p-datatable-thead > tr > th.action-column {
        text-align: center;
      }

      .datatable-responsive .p-datatable.p-datatable-responsive {
        .p-datatable-tbody {
          .bt-leaveType,
          .bt-leaveFromTo {
            .p-column-title {
              display: none;
            }
          }

          .mobile-p-column-title {
            display: block !important;
          }
        }
      }

      .p-datatable .p-datatable-thead > tr,
      .p-datatable .p-datatable-tbody > tr {
        .bt-leaveFromTo {
          .bt-cell-value {
            display: block;

            .bt-cell-render {
              display: flex;
              align-items: center;
            }
          }
        }
      }

      .p-datatable .p-datatable-tbody {
        .p-row-expanded {
          background: #fff;
          display: table-row !important;

          &:hover {
            background: #fff;
          }

          .p-datatable .p-datatable-tbody > tr {
            background: #fff;
          }
        }
      }
    }
  }
}
