/* custom icon - menus */
.hris-home-menu {
  content: url("./icon/icon_home-01.svg");
  width: 28px;
  height: 28px;
}
.hris-notification-menu {
  content: url("./icon/icon_setting-01.svg");
  width: 28px;
  height: 28px;
}
.hris-info-menu {
  content: url("./icon/icon_my-info-01.svg");
  width: 28px;
  height: 28px;
}
.hris-leave-menu {
  content: url("./icon/icon_eLeave-01.svg");
  width: 28px;
  height: 28px;
}
.hris-rental-menu {
  content: url("./icon/icon_eRental-01.svg");
  width: 28px;
  height: 28px;
}
.hris-docs-menu {
  content: url("./icon/icon_my-doc-01.svg");
  width: 28px;
  height: 28px;
}
.hris-policy-menu {
  content: url("./icon/icon_company-policy-01.svg");
  width: 28px;
  height: 28px;
}
.hris-tax-menu {
  content: url("./icon/icon-tax-01.svg");
}
.hris-payslip-menu {
  content: url("./icon/icon_payslip-01.svg");
}
.hris-Nwow-menu {
  content: url("./icon/icon_nwow-01.svg");
  width: 28px;
  height: 28px;
}

/*custom icon - aw*/
.hris-info {
  content: url("./icon/user.svg");
}
.hris-rental {
  content: url("./icon/rental.svg");
}
.hris-tax {
  content: url("./icon/tax.svg");
}
.hris-compensation {
  content: url("./icon/compensation.svg");
}
.hris-leave {
  content: url("./icon/leave.svg");
}
.hris-payslip {
  content: url("./icon/payslip.svg");
}

.hris-docs {
  content: url("./icon/document.svg");
}

.hris-policy {
  content: url("./icon/policy.svg");
}

/*scroll-top*/
.scroll-to-top {
  position: fixed;
  padding: 0;
  top: 0;
  right: 0;
  display: block;
  width: 550px;
  z-index: 1101;
  height: 100%;
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  transform: translateX(100%);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  .scroll-to-top-content-wrapper {
    position: relative;
    height: 100%;
    color: var(--text-color);
    background-color: var(--surface-overlay);
    .scroll-to-top-button {
      opacity: 0.5;
      display: block;
      position: absolute;
      width: 52px;
      height: 52px;
      // line-height: 52px;
      background: var(--primary-color) !important;
      text-align: center;
      color: var(--primary-color-text) !important;
      top: 70%;
      left: -51px;
      z-index: -1;
      overflow: hidden;
      cursor: pointer;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      -webkit-animation-name: rubberBand;
      animation-name: rubberBand;
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-iteration-count: 3;
      animation-iteration-count: 3;
      -webkit-animation-delay: 5s;
      animation-delay: 5s;

      // @include sm-screen {
      //   top: 70%;
      // }
      i {
        font-size: 26px;
        line-height: inherit;
        cursor: pointer;
        transform: rotate(1turn);
        transition: transform 1s;
      }

      &:visited {
        transform: rotate(0deg);
      }
    }
  }
}
