@import "../../../../../style/utils/";

.rental-submit-claim {
  padding-bottom: 60px;

  .rental-submit-claim-header {
    padding: 1.5rem 0.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .title-group {
      button {
        display: none;
        margin: 0 0.5rem;
        padding-bottom: 0;
        padding-top: 3px;

        .p-button-icon {
          font-size: 2rem;
          color: black;
        }
        .p-button-label {
          display: none;
        }
      }

      .title {
        font-size: 20px;
        font-weight: 400;
      }

      .desc {
        margin-top: 15px;
      }
    }
  }

  .claim-table-summary {
    background: #fff;
    padding: 25px;
  }

  .rental-form-title {
    display: flex;
    align-items: center;
    margin: 5px 25px 25px 25px;

    & > h2 {
      font-weight: 400;
      margin: 0;
    }
    button {
      display: none;
      margin: 0 0.5rem;
      .p-button-icon {
        font-size: 2rem;
        color: black;
      }
      .p-button-label {
        display: none;
      }
    }

    @include sm-screen {
      margin: 0.8rem 0;
      button {
        display: block;
        margin: 0 0.5rem;
        & > span {
          font-size: 2rem;
          color: black;
        }
      }
    }
  }

  .footer {
    background-color: var(--surface-d);
    height: 60px;
    margin: -1.8rem -1.6rem;
    padding: 0rem 1.6rem;
    width: calc(100vw - 6rem);
    position: fixed;
    bottom: 25px;
    right: 21px;
    z-index: 9999;

    @include md-screen {
      left: 107.2px;
    }

    .secondary {
      color: var(--secondary-color-text);
      border-color: var(--secondary-color-text);
    }

    .p-button {
      min-width: 70px;
      display: inline;
    }

    .next-button {
      margin-left: 1rem;
    }
  }

  @include sm-screen {
    margin: -0.5rem;

    & > h2 {
      font-weight: 400;
      margin-left: 0;
    }

    .rental-submit-claim-header {
      padding: 0.75rem 0.5rem 0.5rem 0.5rem;

      .title-group {
        align-items: center;

        .title-group-main {
          display: flex;
          align-items: center;
          padding-bottom: 0.5rem;
          border-bottom: 1px solid #e0e0e0;
        }

        button {
          display: inline-flex;
        }

        .desc {
          margin: 1rem;
        }
      }

      .start-upload-btn {
        width: 100%;
        margin: 0rem 0.5rem 0.5rem 0.5rem;
        justify-content: center;
      }
    }
  }
}
