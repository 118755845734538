/* Notification Details - Card Container */
.notification-details-card-container {
  margin-top: 3rem;
}

/* Notification Details - Container */
.notification-details-container {
  width: 100%;
  margin-bottom: 1rem;
  position: relative;
  background-color: var(--surface-a);
}

/* Notification Details - Grid */
.notification-grid {
  flex-wrap: wrap;
  margin-right: 0rem;
  margin-left: 0rem;
  margin-top: 0rem;
}

/* Notification Details - Content Box */
.notification-content {
  flex-wrap: wrap;
  padding: 1rem 0rem 0rem 0rem;
}

/* Notification Details - Item Heading */
.notification-item {
  margin-left: 1rem;
  width: 20%;
}

/* Notification Details - Item Content */
.notification-item-content {
  width: 70%;
}

/* Notification Details - Take Action Button Container */
.notification-button {
  justify-content: flex-end;
  padding: 0rem;
  width: 20%;
  text-align: right;
}

.notification-item-button {
  margin-right: 1rem;
  margin-left: auto;
}

.notification-icon-left {
  padding-right: 1rem;
}

/* Notification Details - Item Content Message Container */
.notification-item-content-message {
  width: 100%;
  height: 13rem;
  margin-right: 1rem;
  padding: 0rem 0rem 0rem 1rem;
  background-color: var(--surface-b);
}

/* Notifcation Details Previous, Back to Top and Next Control Panel */
.n-d-c-c-control-panel {
  background-color: var(--primary-color);
}

.n-d-c-c-center > .n-d-c-c-btn-center {
  width: 72vw;
  height: 2.2rem;
}
