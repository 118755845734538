@import "../../style/utils";

.bt-table .bt-cell-value {
  word-break: break-word;
}

@include sm-screen {
  .datatable-responsive
    > .mobile-view.disable-mobile
    > .p-datatable-scrollable-wrapper
    > .p-datatable-scrollable-view
    > .p-datatable-scrollable-header
    > .p-datatable-scrollable-header-box
    > table.p-datatable-scrollable-header-table {
    display: table;
  }

  .datatable-responsive
    .p-datatable.p-datatable-responsive:not(.mobile-expander).disable-mobile
    .p-datatable-thead
    > tr
    > th,
  .datatable-responsive
    .p-datatable.p-datatable-responsive.disable-mobile
    .p-datatable-tfoot
    > tr
    > td {
    display: table-cell !important;
    padding: 0.5rem;
  }

  .datatable-responsive:not(.expander-datatable)
    .disable-mobile
    .p-datatable-scrollable-body {
    overflow: auto;
  }
  .datatable-responsive
    .disable-mobile
    table.p-datatable-scrollable-body-table {
    table-layout: fixed;
  }

  .datatable-responsive
    .p-datatable.p-datatable-responsive.disable-mobile
    table:not(.mobile-expander-header-style)
    .p-datatable-tbody
    > tr
    > td {
    float: none;
  }

  .datatable-responsive
    .p-datatable.p-datatable-responsive.disable-mobile
    .p-datatable-tbody
    > tr
    > td:not(.table-expandable-header)
    .p-column-title {
    display: none;
  }
}
