@import "./../../../style/index.scss";
.base-container {
  height: 100%;
}

#admin {
  .title {
    font-size: 20px;
    font-weight: 400;
  }

  .base_multi_step_form {
    &-show-more-btn {
      display: none;
    }
    &-group {
      margin: 2rem 0;
    }
  }

  .header {
    // > .filter-menu {
    //   margin-left: 0;
    //   margin-right: 0;
    // }
    .panel-header {
      padding: 0;
      border: none;
      background: #fff;
      display: flex;
      justify-content: space-between;
    }
    .panel-content {
      padding-top: 1.5rem;
    }
    > .form-menu {
      @include sm-screen {
        margin: 2rem 0;
      }
      margin-bottom: 0;
    }
  }

  .form-loading {
    @include sm-screen {
      margin: 20px 0px;
    }
  }
  .footer {
    background-color: var(--surface-d);
    height: 60px;
    margin: -1.8rem -1.6rem;
    padding: 0rem 1.6rem;
    width: calc(100vw - 6rem);
    position: fixed;
    bottom: 25px;
    right: 21px;
    z-index: 1000;
    @include sm-screen {
      width: calc(100vw);
      position: fixed;
      right: 22px;
    }

    @include md-screen {
      left: 107.2px;
    }

    .secondary {
      color: var(--secondary-color-text);
      border-color: var(--secondary-color-text);
    }

    .p-button {
      min-width: 70px;
      display: inline;
    }

    .next-button {
      margin-left: 1rem;
    }
  }
}

.file-uploader-container {
  .support-file-upload {
    margin: 1rem 0 0 0;

    .file-upload__header {
      background: var(--surface-d) !important;
    }
  }
  .support-file-upload {
    .p-fileupload-content {
      .p-fileupload-files {
        .p-fileupload-row {
          display: flex;
          flex-direction: row;

          .file-uploader-items {
            .support-document-item-template {
              -ms-word-break: break-all;
              /* Instead use this non-standard one: */
              word-break: break-word;
              white-space: pre-wrap;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }

            button:nth-child(2) {
              margin: 0 1rem 0 0;
            }
          }
        }
      }
    }
  }
}

.multimenu-style-tab {
  .bt-table .bt-header .bt-toolbar {
    justify-content: flex-end;
    .bt-dump {
      display: none;
    }
    @include sm-screen {
      flex-direction: row-reverse;
    }
  }
  .bt-table {
    //chip style in table
    .bt-cell-value {
      .p-chip.p-component {
        background-color: #dee2e6;
        color: #495057;
        border-radius: 5px;
        display: inline-flex;
        justify-content: flex-end;
        padding: 5px;
        margin: 1px;
      }
    }
  }
  .add-button {
    color: var(--primary-color);
    background: var(--primary-color-text);
    border: none;
  }
  .add-button:hover {
    color: var(--primary-color-text);
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
  }

  //table style
  .bt-table.bt-table-custom {
    .action-column > div {
      display: flex;
      justify-content: flex-end;
    }
    .action-column > div > .p-column-title {
      display: none !important;
    }
  }

  //expander content style
  .p-datatable-mobiletable {
    display: none;

    @include sm-screen {
      display: block;

      .p-datatable-thead {
        display: none;
      }

      .p-datatable-tbody td {
        width: 100% !important;
      }
      .table-expandable-row {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  @include sm-screen {
    .dropdown-wrapper {
      padding: 0 0 1.5rem;
      .p-dropdown {
        width: 100%;
      }
    }
  }

  .header {
    margin: 0;
  }

  .menu-tab {
    .dropdown-wrapper {
      display: none;
    }
    .tabmenu-wrapper {
      display: block;
    }
    @include sm-screen {
      .dropdown-wrapper {
        display: block;
      }
      .tabmenu-wrapper {
        display: none;
      }
    }
  }

  .header-sticky {
    position: sticky;
    top: 0;
  }

  .form-title {
    display: flex;
    align-items: center;

    & > h2 {
      font-weight: 400;
    }
  }
}

.admin-form-style {
  @include sm-screen {
    .dropdown-wrapper {
      padding: 0 0 1.5rem;
      .p-dropdown {
        width: 100%;
      }
    }
  }

  .upload-control {
    .file-upload {
      min-height: 100px;
    }
  }

  padding-bottom: 60px;

  .form-title {
    display: flex;
    align-items: center;

    & > h2 {
      font-weight: 400;
    }
    button {
      display: none;
      margin: 0 0.5rem;
      padding-bottom: 0;
      .p-button-icon {
        font-size: 2rem;
        color: black;
      }
      .p-button-label {
        display: none;
      }
    }
  }

  .form-id {
    font-size: 14px;
    color: #8d8d8d;
  }

  @include sm-screen {
    .header {
      margin: 0;
    }

    .header-sticky {
      position: sticky;
      top: 0;
    }

    .form-title {
      margin: 0;
      button {
        display: block;
        margin: 0 0.5rem;
        & > span {
          font-size: 2rem;
          color: black;
        }
      }
    }

    .form-id {
      margin-left: 48px;
    }

    .steps {
      margin: 1.5rem 0rem 0rem 0rem;
    }
  }
}
